@mixin link-button {
  background-color: transparent;
  color: $rain;
  text-transform: capitalize;
  height: gux(2);
  line-height: gux(2);
  padding: gux(0);

  &:active,
  &:hover,
  &:focus {
    background-color: transparent;
    color: $rain;
  }

  &:disabled {
    color: $silver;
  }
}
