@import "../../styles/mixins/general";
@import "../../styles/variables";
@import "../../../node_modules/@q4/nimbus-ui/dist/_mixins.scss";

@include app-namespace-wrap() {
  $badge-font-size: 9px;

  .meeting-form {
    &_description {
      .nui-textbox_input {
        min-height: 162px;
      }
    }

    &_tooltip-field {
      .nui-field_label-text {
        display: inline-block;
        overflow: visible;
        @include tooltip-appear();
      }

      .nui-badge {
        vertical-align: top;
        font-size: $badge-font-size;
        padding: gux(0.25);
      }
    }

    &,
    &_suggested-time {
      margin: 0 auto;
      max-width: 600px;
    }

    &_suggested-time {
      &-label {
        margin-left: gux(2);
      }

      &-popover {
        .nui-button_label {
          white-space: nowrap;
        }
      }

      > .nui-button {
        height: gux(5);
        width: 100%;
      }
    }

    .nui-chips {
      margin-top: gux(0.5);
      margin-bottom: gux();

      .nui-chip {
        &::before {
          content: "";
          height: gux(4);
          width: gux(0.5);
          position: absolute;
          left: 0;
          border-top-left-radius: gux();
          border-bottom-left-radius: gux();
        }

        &_label {
          padding-left: gux(0.5);
          max-width: 90%;
        }
      }
    }

    &_chips {
      &--corporate {
        > .nui-chip::before {
          background: $dark-teal;
        }
      }

      &--investor {
        > .nui-chip::before {
          background: $ink;
        }
      }

      &--other {
        > .nui-chip::before {
          background: $cherry;
        }
      }
    }
  }

  .nui-day-picker-calendar_day--disabled {
    color: $silver;
  }
}
