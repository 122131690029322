@import "../../../../../../../../styles/mixins/general";
@import "../../../../../../../../styles/variables";
@import "../../../../../../../../../node_modules/@q4/nimbus-ui/dist/variables";

@include app-namespace-wrap() {
  .attendee-password-modal {
    &_password-textbox {
      width: gux(40);
    }

    &_copy-button {
      margin-top: gux(4);
    }

    .nui-modal {
      &_header {
        padding: gux(3) gux(4) 0 gux(3);
      }

      &_title {
        font-weight: $font-weight--normal; // styled here to avoid header dom nesting console error
      }

      &_chin {
        border: none;
      }

      &_content {
        .simplebar-content-wrapper {
          background-color: $white;
        }
      }

      &_children {
        padding: gux(3) 0 0 gux(3);
      }
    }
  }
}
