@import "../../../../../../node_modules/@q4/nimbus-ui/dist/variables";
@import "../../../../../styles/mixins/general";
@import "../../../../../styles/mixins/responsive";
@import "../../../../../styles/mixins/text";
@import "../../../../../styles/variables";
@import "../../../../../styles/itinerary/variables";

@include app-namespace-wrap() {
  $width--dial-in: 160px;

  .agenda {
    &_actions {
      .agenda_link {
        color: $branding_secondary;
        margin-left: gux(2);
      }
    }

    &_actions,
    .section_header {
      display: flex;
      align-items: center;
    }

    &_loader {
      position: relative;
      display: inline-block;
      margin-left: gux(6);
      vertical-align: middle;

      span {
        @include sr-only();
      }
    }

    &_show-past-toggle {
      margin-left: gux(2);
      margin-right: 0;
    }

    &_group {
      &:not(:first-child) {
        margin-top: gux(4);
      }
    }

    &_date {
      margin: 0 0 gux(3);
    }

    &_list {
      border-collapse: separate;
    }

    &_item {
      position: relative;
      display: flex;

      &:focus {
        outline: none;
      }

      &:focus,
      &:hover {
        .agenda_remove {
          opacity: $opacity--full;
        }
      }

      &--disabled {
        cursor: default;

        & > .agenda_cell {
          opacity: $opacity--medium;
        }
      }
    }

    &_cell {
      border: 3px solid transparent;
      padding: 28px;
      background-color: rgba($branding_secondary_background_rgb, $opacity--medium);
      background-clip: padding-box;
      transition: $transition-duration--default $transition-timing-function--default;
      transition-property: opacity, color;
    }

    &_session:not(:last-child) {
      margin-bottom: gux(2);
    }

    &_time {
      text-align: center;
      flex-basis: 240px;
      padding: 28px 20px;

      > span {
        font-size: $agenda-time-font-size;
        font-weight: 300;
      }

      span {
        & + span:before {
          content: " – ";
        }
      }
    }

    &_room {
      > span {
        font-size: $agenda-room-font-size;
        font-weight: 400;
      }

      padding-top: gux();
    }

    &_session-type {
      margin-top: gux(1.5);
      text-align: center;

      span {
        background-color: $branding_secondary;
        color: $branding_secondary-contrast;
        border-radius: $border-radius--large;
        padding: 0 gux(2);
      }

      &--long {
        background-color: $branding_secondary;
        color: $branding_secondary-contrast;
        border-radius: $border-radius--xlarge;
        padding: gux(0.5) gux(2);
        margin-top: gux(1.5);
      }
    }

    &_dial-in {
      width: $width--dial-in;
      .nui-anchor {
        color: $branding_secondary;
      }
    }

    &_session-detail {
      display: flex;
      width: 100%;
    }

    &_title {
      flex: 1;
      padding-right: gux(2);

      .itinerary_subheading {
        line-height: 1;
      }

      .itinerary_inner {
        margin-top: gux();
      }
    }

    &_label {
      font-weight: $font-weight--bold;
    }

    &_description {
      white-space: break-spaces;
    }

    &_vendor {
      margin-bottom: gux(2);
    }

    &_vendor_override_dial_in_numbers {
      margin-top: gux(2);

      .agenda_dial-in_column {
        margin-bottom: 0.4em;
      }
    }

    &_remove {
      opacity: $opacity--transparent;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      padding: gux(2);
      cursor: pointer;
      transition: all $transition-duration--default $transition-timing-function--default;
      transition-property: color, opacity;

      &:focus,
      &:hover {
        color: $branding_secondary;
      }

      &:focus {
        opacity: $opacity--full;
        outline: none;
      }

      &:hover {
        .nui-tooltip {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    &_popover-menu {
      .nui-popover-menu {
        &_inner {
          margin: 0;
          background-color: $branding_tertiary;

          &::before {
            border: none;
          }
        }
      }

      .agenda_export-button {
        margin: 0;
      }
    }

    &_export-button.nui-button {
      height: auto;
      padding: gux();
      border: 1px solid rgba($white, $opacity--subtle);
      border-radius: 0;
      font-size: inherit;
      line-height: inherit;
      font-weight: inherit;

      &:focus,
      &:hover {
        color: $branding_secondary;
        border-color: $branding_secondary;
      }

      &--disabled {
        width: auto;
        padding-left: 0;
        border: none;

        .nui-button {
          &_label {
            opacity: 1;
          }
        }
      }
    }

    .section {
      &_header {
        justify-content: space-between;

        .itinerary_heading {
          display: inline;
        }
      }
    }

    @include tablet-and-below() {
      &_session {
        margin-top: gux(4);
      }

      &_item {
        flex-direction: column;
        align-content: stretch;
      }

      &_cell {
        border: none;
        padding-left: 28px;
        padding-right: gux(8);
      }

      &_time {
        flex-basis: unset;
        padding-top: gux(4);
        padding-bottom: gux(1.5);
      }

      &_remove {
        width: gux(8);

        .nui-tooltip {
          display: none;
        }
      }

      &_actions {
        margin-top: gux(4);
      }

      .section_header {
        align-items: unset;
      }
    }
  }
}
