@import "../../../../styles/mixins/general";
@import "../../../../styles/mixins/responsive";
@import "../../../../styles/variables";
@import "../../../../styles/itinerary/variables";

@include app-namespace-wrap() {
  .timezone-selector {
    &--margin {
      margin-left: gux(2);
    }

    &_icon {
      padding-right: gux();
      color: $branding_secondary;
    }

    &_label {
      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }

    .anchor-popover-menu_anchor {
      text-decoration: none;
    }
  }
}
