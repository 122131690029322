@import "../../../../../../../styles/mixins/general";
@import "../../../../../../../styles/mixins/q4icons";
@import "../../../../../../../styles/variables";
@import "../../../../../../../styles/itinerary/variables";

@include app-namespace-wrap() {
  $panel-width: 250px;

  $panel-image-width: 62px;
  $panel-image-height: 74px;

  $panel-selected-size: 5px;

  .compact-panel {
    position: relative;
    display: flex;
    cursor: pointer;
    width: $panel-width;
    padding: $panel-selected-size * 2;

    & + .compact-panel {
      margin-left: gux(4);
    }

    &:focus {
      outline: none;
    }

    &_section {
      display: flex;
      flex-direction: column;

      &--grow {
        flex: 1 0;
      }
    }

    &_image {
      width: $panel-image-width;
      height: $panel-image-height;
      background-image: url("../../../../../../../assets/itinerary/anon.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      margin: 0 gux(2) gux(2) 0;
    }

    &_action {
      pointer-events: none;
    }

    &:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: opacity $transition-duration--default $transition-timing-function--default;
      content: "";
      opacity: 0;
      border: $panel-selected-size solid $branding_secondary;
      border-radius: $border-radius--small;
    }

    &_action {
      @include q4-icon-after($q4i-caret-left-4pt);
      &:after {
        display: inline-block;
        color: $branding_secondary;
        transform: rotate(180deg);
        font-size: 10px;
      }
    }

    &_category,
    &_title,
    &_detail {
      white-space: normal;
    }

    &_category,
    &_title {
      font-weight: $font-weight--bold;
    }

    &_category {
      text-transform: uppercase;
      font-size: $panel-category-font-size;
      line-height: $panel-category-line-height;
    }

    &_title {
      font-size: $panel-title-font-size;
      line-height: $panel-title-line-height;
      margin-bottom: gux(0.5);
    }

    &_detail {
      font-size: $panel-details-font-size;
      line-height: $panel-details-line-height;

      & + .compact-panel_detail {
        margin-top: gux(0.5);
      }
    }

    &--selected {
      &:after {
        opacity: 1;
      }
    }
  }
}
