@import "../../../../../styles/variables";
@import "../../../../../styles/mixins/_general";

@include admin-app-namespace-wrap() {
  .badge {
    padding: gux(0.25) gux(0.5);
    border-radius: $border-radius--small;
    font-size: $font-size--base;
    line-height: $line-height--base;
    text-align: center;

    &--black-smoke {
      color: $white;
      background: $black-smoke;
    }

    &--citrus {
      color: $slate;
      background: $citrus;
    }

    &--soft-grey {
      color: $light-slate;
      background: $soft-grey;
    }

    &--light-grey {
      color: $light-slate;
      background: $light-grey;
    }

    &--light-slate {
      color: $white;
      background: $light-slate;
    }

    &--rain {
      color: $white;
      background: $rain;
    }

    &--slate {
      color: $white;
      background: $slate;
    }

    &--silver {
      color: $white;
      background: $silver;
    }

    &--spice {
      color: $white;
      background: $spice;
    }

    &--steel {
      color: $white;
      background: $steel;
    }

    &--surf {
      color: $white;
      background: $surf;
    }

    &--teal {
      color: $white;
      background: $teal;
    }

    &--tangerine {
      color: $white;
      background: $tangerine;
    }

    &--mustard {
      color: $white;
      background: $mustard;
    }

    &--q4-blue {
      color: $white;
      background: $q4-blue;
    }

    &_text {
      &--black-smoke {
        color: $black-smoke;
      }

      &--citrus {
        color: $citrus;
      }

      &--light-grey {
        color: $light-grey;
      }

      &--light-slate {
        color: $light-slate;
      }

      &--rain {
        color: $rain;
      }

      &--slate {
        color: $slate;
      }

      &--silver {
        color: $silver;
      }

      &--spice {
        color: $spice;
      }

      &--steel {
        color: $steel;
      }

      &--surf {
        color: $surf;
      }

      &--teal {
        color: $teal;
      }

      &--tangerine {
        color: $tangerine;
      }

      &--mustard {
        color: $mustard;
      }

      &--q4-blue {
        color: $q4-blue;
      }
    }

    &--icon {
      padding: gux() gux();
      font-size: $font-size--icon;
      border-radius: $border-radius--circle;
    }

    &--small {
      padding: gux(0.25) gux(0.5);
      font-size: $font-size--caption;
    }
  }
}
