@import "../../styles/mixins/general";
@import "../../styles/variables";

@include app-namespace-wrap() {
  .corporate-profile-form {
    &_description {
      .nui-textbox_input {
        min-height: 162px;
      }
    }

    &_questions-header {
      margin-bottom: gux();
    }

    &,
    &_suggested-time {
      margin: 0 auto;
      max-width: 800px;
    }

    &_suggested-time {
      &-label {
        margin-left: gux(2);
      }

      &-popover {
        .nui-button_label {
          white-space: nowrap;
        }
      }
    }

    .nui-chip {
      &_label {
        max-width: none;
      }
    }
  }

  .nui-day-picker-calendar_day--disabled {
    color: $silver;
  }

  .corporate-profile-availability-form {
    margin: 0 auto;
    max-width: 800px;

    &_subheader {
      margin-top: gux(1);
      margin-bottom: gux(3);
      text-align: center;
    }
  }
}
