@import "../../../../../../../node_modules/@q4/nimbus-ui/dist/variables";
@import "../../../../../../../node_modules/@q4/nimbus-ui/dist/mixins";
@import "../../../../../../styles/mixins/general";
@import "../../../../../../styles/mixins/nimbus";

@include admin-app-namespace-wrap() {
  .meeting-configuration-content {
    margin-top: gux(7);
    margin-left: gux(4);
    margin-right: gux(4);

    &_tabs {
      .nui-tabs {
        flex-wrap: wrap;

        &_item {
          flex-grow: 1;
          padding: gux(2) gux();
          height: auto;
          background-color: $ghost;

          &--selected {
            color: $steel;
            font-weight: $font-weight--bold;
            border-color: $light-grey;
            background-color: $light-grey;
          }
        }
        &_label {
          text-transform: capitalize;
        }
      }
    }
  }
}
