@import "../../../../../styles/mixins/general";
@import "../../../../../styles/mixins/responsive";
@import "../../../../../styles/variables";
@import "../../../../../styles/itinerary/variables";

@include app-namespace-wrap() {
  .section {
    position: relative;
    padding: 0 $padding--horizontal;
    overflow: hidden;

    &_inner {
      max-width: 1366px;
      margin: 0 auto;
      padding: $padding--vertical 0;

      &:before,
      &:after {
        position: absolute;
        left: $padding--horizontal;
        right: $padding--horizontal;
        display: block;
        height: 1px;
        background-color: rgba($white, $opacity--subtle);
      }

      &:before {
        top: 0;
      }

      &:after {
        bottom: 0;
      }

      &--contained {
        max-width: 1000px;
      }

      &--narrow {
        max-width: 840px;
      }

      &--thin {
        padding: ($padding--vertical / 8 * 6) 0 ($padding--vertical / 8 * 7);
      }

      &--key-line {
        &-top {
          &:before {
            content: "";
          }
        }

        &-bottom {
          &:after {
            content: "";
          }
        }

        &-both {
          &:before,
          &:after {
            content: "";
          }
        }
      }
    }

    &_header {
      margin-bottom: gux(5);

      .itinerary_heading,
      .itinerary_subheading {
        display: inline;
        vertical-align: baseline;
      }

      &--thick {
        margin-bottom: $padding--horizontal;
      }
    }

    &_anchor {
      display: none;
    }

    &--dark {
      background-color: $branding_tertiary;
    }

    &--black {
      background-color: $black;

      &:before {
        content: "";
        position: absolute;
        top: gux(-1.5);
        left: calc(50% - #{gux(-1.5)});
        width: gux(3);
        height: gux(3);
        background-color: $branding_tertiary;
        transform: rotate(45deg);
      }
    }

    &--transparent {
      background-color: transparent;
    }

    &--gradient {
      background-image: linear-gradient(to top, $branding_tertiary calc(100% - 170px), transparent 100%);
    }

    @include tablet-and-below() {
      &_header {
        flex-direction: column;

        &,
        &--thick {
          margin-bottom: gux(5);
        }

        .itinerary_heading,
        .itinerary_subheading {
          display: block;
        }
      }

      &--black {
        &:before {
          content: none;
        }
      }
    }

    @include tablet() {
      padding: 0 $padding-tablet--horizontal;

      &_inner {
        &,
        &--thin {
          padding: $padding-tablet--vertical 0;
        }

        &:before,
        &:after {
          left: $padding-tablet--horizontal;
          right: $padding-tablet--horizontal;
        }
      }

      &_header {
        flex-direction: column;

        .itinerary_heading,
        .itinerary_subheading {
          display: block;
        }
      }
    }

    @include phone() {
      padding: 0 $padding-phone--horizontal;

      &_inner {
        &,
        &--thin {
          padding: $padding-phone--vertical 0;
        }

        &:before,
        &:after {
          left: $padding-phone--horizontal;
          right: $padding-phone--horizontal;
        }
      }
    }
  }
}
