@import "../../../../../styles/mixins/general";
@import "../../../../../styles/mixins/responsive";
@import "../../../../../styles/variables";
@import "../../../../../styles/itinerary/variables";

@include public-app-namespace-wrap() {
  .tracks {
    margin-top: -$padding--vertical;
    overflow: hidden;

    .section {
      &_inner {
        padding-bottom: $padding--vertical / 10 * 7;
      }

      &_header {
        display: flex;
        align-items: baseline;
      }
    }

    &_group {
      &:not(:first-child) {
        margin-top: gux(4);
      }
    }

    &_item {
      & + .tracks_item {
        margin-left: 32px;
      }

      &:focus {
        outline: none;
      }

      .panel_category {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &_name {
      margin: 0 0 gux(3);
    }

    .itinerary_subheading {
      display: flex;
      align-items: baseline;
    }

    @include tablet {
      margin-top: -$padding-tablet--vertical;

      .section {
        &_inner {
          padding-bottom: $padding-tablet--vertical / 10 * 7;
        }
      }
    }

    @include phone() {
      margin-top: -$padding-phone--vertical;

      .section {
        &_inner {
          padding-bottom: $padding-phone--vertical / 10 * 7;
        }
      }
    }
  }
}
