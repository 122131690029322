@import "../../../../../node_modules/@q4/nimbus-ui/dist/variables";
@import "../../../../styles/mixins/general";
@import "../../../../styles/mixins/nimbus";
@import "../../../../styles/mixins/responsive";

@include app-namespace-wrap() {
  .conference-edit {
    &_column {
      &--design {
        padding-left: gux(6);
      }
    }

    &_delete-button {
      @include position-button--absolute-left;
    }

    &_type {
      .#{$namespace}field_children {
        flex-direction: row;
        justify-content: left;
      }

      .#{$namespace}toggle-input-base {
        &_label {
          padding-bottom: 0;
        }

        &:not(:first-child) {
          margin-left: gux();
        }
      }
    }

    &_presentation-vendor {
      .#{$namespace}field_children {
        justify-content: left;
      }
    }

    &_meeting-location {
      padding-top: gux();
    }

    .#{$namespace}keyline {
      margin-top: 0;
    }

    .#{$namespace}modal_content {
      background-color: $pale-grey;
    }

    @include tablet-and-below() {
      &_column {
        &--design {
          padding-left: gux(3);
        }
      }
    }

    &_line-divider {
      margin: 0 0 1em;
      background: $light-grey;
    }

    &_full-width-input,
    &_full-width-input.nui-day-picker {
      width: 100%;
      max-width: 100%;
    }

    .nui-info-icon {
      &--default {
        width: gux(1.5);
        height: gux(1.5);
        margin-left: gux(0.5);
        margin-bottom: gux(0.25);
      }
      &_button {
        width: gux(1.5);
        height: gux(1.5);
        line-height: gux(1.5);
        background: $ghost;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &_icon {
        font-size: gux();
        color: $slate;
      }
    }

    .nui-field {
      &_label-text {
        display: inline-block;
        overflow: visible;
      }

      &_children > :first-child:last-child {
        width: 106%;
      }

      .nui-tooltip {
        max-width: fit-content;

        &--extra-padding {
          width: fit-content;
          padding: gux(0.5) gux(1.25);
        }
      }
    }
  }
}
