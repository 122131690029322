@import "../../styles/mixins/general";
@import "../../styles/variables";

@include app-namespace-wrap() {
  .ondemand-form {
    &_description {
      .nui-textarea_input {
        min-height: 142px;
      }
    }

    .nui-chip {
      &_label {
        max-width: none;
      }
    }
  }

  .nui-day-picker-calendar_day--disabled {
    color: $silver;
  }
}
