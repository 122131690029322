@import "../../../../../../styles/mixins/general";
@import "../../../../../../../node_modules/@q4/nimbus-ui/dist/variables";
@import "../../../../../../../node_modules/@q4/nimbus-ui/dist/_mixins.scss";

@include app-namespace-wrap() {
  .conference-timeline-edit {
    .#{$namespace}keyline {
      margin-top: 0;
    }
    &_custom-label {
      .nui-field_label-text {
        display: inline-block;
        overflow: visible;
        @include tooltip-appear();
      }

      .info-bubble_badge {
        margin-left: gux(0.5);
      }

      .info-bubble_tooltip {
        white-space: normal;
      }

      .nui-badge--small {
        padding: gux(0.25);
      }
    }

    .nui-day-picker {
      max-width: inherit;
    }
  }
}
