@import "../../../../styles/mixins/general";
@import "../../../../styles/variables";

@include app-namespace-wrap() {
  .header {
    &_profile-button {
      text-transform: none;
      font-size: $font-size--subheader;

      .nui-button {
        &_icon,
        &_label {
          vertical-align: middle;
        }

        &_icon {
          font-size: $font-size--title;
        }
      }
    }
  }
}
