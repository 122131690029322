@import "../../styles/mixins/general";
@import "../../styles/mixins/responsive";
@import "../../styles/variables";
@import "../../styles/itinerary/variables";

@include app-namespace-wrap() {
  .anchor-popover-menu {
    display: inline;
    vertical-align: baseline;

    &_anchor {
      text-decoration: none;
      color: $branding_font-color--base;
      font-size: $font-size--base;

      &:hover,
      &:focus {
        color: $branding_secondary;
      }
    }

    &_popover-menu-item.nui-button {
      height: auto;
      width: auto;
      padding: gux();
      border: 1px solid rgba($white, $opacity--subtle);
      border-radius: 0;
      font-size: inherit;
      line-height: inherit;
      font-weight: inherit;
      text-align: left;

      > .nui-button_label {
        text-transform: uppercase;
        white-space: nowrap;
      }

      &:focus,
      &:hover {
        color: $branding_secondary;
        border-color: $branding_secondary;
      }

      &--disabled {
        width: auto;
        padding-left: 0;
        border: none;

        .nui-button {
          &_label {
            opacity: 1;
          }
        }
      }
    }

    &_popover-menu {
      .nui-popover-menu {
        &_inner {
          &::before {
            display: none;
          }

          margin-top: gux(0.5);
          background-color: $branding_tertiary;
        }
      }

      .anchor-popover-menu_popover-menu-item {
        margin: 0;
      }
    }

    &--open {
      .anchor-popover-menu_popover-menu-item.nui-button {
        color: $branding_secondary;
      }
    }

    &--empty {
      pointer-events: none;
    }
  }
}
