@import "../../../../../../../../../node_modules/@q4/nimbus-ui/dist/variables";
@import "../../../../../../../../../node_modules/@q4/nimbus-ui/dist/mixins";
@import "../../../../../../../../styles/mixins/general";

@include admin-app-namespace-wrap() {
  .scheduler-metric-bar {
    padding: 0 gux() gux();
    display: flex;
    justify-content: space-between;
    background-color: $light-grey;
  }
}
