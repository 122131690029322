@import "../../../../../styles/mixins/general";
@import "../../../../../../node_modules/@q4/nimbus-ui/dist/variables";
@import "../../../../../components/companyBranding/companyBranding.component.scss";

@include public-app-namespace-wrap() {
  .corporate-attendees {
    .nui-info-icon--default {
      width: gux(1.5);
      height: gux(1.5);
      margin-left: gux(0.5);
      margin-bottom: gux(0.25);

      .nui-info-icon_button {
        width: gux(1.5);
        height: gux(1.5);
        line-height: gux(1.5);
        background: $ghost;
      }

      .nui-info-icon_icon {
        font-size: gux();
        color: $black;
      }

      .nui-field_label-text {
        display: inline-block;
        overflow: visible;
      }

      .nui-tooltip {
        max-width: gux(49);
        width: gux(49);
      }
    }

    a {
      color: $branding_secondary;
      text-decoration: none;
      &:focus,
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
