@import "../../../../../../../../../node_modules/@q4/nimbus-ui/dist/variables";
@import "../../../../../../../../../node_modules/@q4/nimbus-ui/dist/mixins";
@import "../../../../../../../../styles/mixins/general";
@import "../../../../../../../../styles/mixins/nimbus";
@import "../../../../../../../../styles/variables";

@include app-namespace-wrap() {
  .timetable {
    &-header,
    &-content {
      width: 100%;
      display: flex;
      flex: 0 0 auto;
    }

    &-header {
      border-bottom: 2px solid $silver;

      > div:not(:last-child) {
        border-right: 1px solid $soft-grey;
      }

      > div:nth-child(2n) {
        background: $light-grey;
      }

      > div {
        height: gux(4);
        line-height: gux(4);
        text-align: center;
        vertical-align: middle;
      }
    }

    &-header_time,
    &-content_time {
      width: gux(17);
    }

    &-content_time {
      .timetable-content_slot {
        border-right: 2px solid $soft-grey;
        padding-left: gux();

        &--pseudo {
          background: $light-grey;
          pointer-events: none;
        }
      }

      .nui-keyline {
        height: 2px;
      }
    }

    &-header_resource,
    &-content_resource {
      min-width: auto;
      flex: 1 0;
      text-align: center;

      .nui-keyline {
        height: 2px;
      }
    }

    &-content_slot {
      height: gux(10);
      width: 100%;
      display: flex;
      align-items: center;
    }

    &-header_resource {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 gux();
    }

    &-content_resource {
      .timetable-content_slot {
        justify-content: space-evenly;

        &--blocked {
          background: repeating-linear-gradient(-55deg, $white, $white 6px, $light-grey 2px, $light-grey 10px);
          border-radius: 0;
          cursor: default;
          pointer-events: none;
        }

        &--pseudo {
          background: $light-grey;
          pointer-events: none;
        }

        &-event {
          color: white;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: gux();
          cursor: pointer;
          pointer-events: all;

          &-title {
            font-weight: $font-weight--bold;
            font-size: gux(1.5);
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: gux(2);
            max-height: gux(4);
          }
        }

        &-highlight {
          transition: all $transition-duration--default ease-in-out;
          height: 100%;
          width: 100%;
          border-radius: gux();
        }
      }

      &--main {
        .timetable-content_slot {
          &-highlight {
            &:hover,
            &:focus {
              border: 1px solid $teal;
              background-color: rgba($teal, $opacity--subtle);
            }
          }

          &-event {
            background-color: $dark-teal;
            color: white;

            &-presentation {
              background-color: $light-slate;
              pointer-events: none;
            }

            &--pseudo {
              background-color: $light-grey;
              color: $light-slate;
              pointer-events: none;
            }
          }
        }
      }

      &--alt {
        .timetable-content_slot {
          &-highlight {
            &:hover,
            &:focus {
              border: 1px solid $q4-blue;
              background-color: rgba($q4-blue, $opacity--subtle);
            }
          }

          &-event {
            background-color: $q4-blue;
            color: white;

            &--pseudo {
              background-color: $light-grey;
              color: $light-slate;
              pointer-events: none;
            }
          }
        }
      }

      &--main,
      &--alt {
        .timetable-content_slot {
          &-event-break {
            background-color: $dove-grey;
            pointer-events: none;
          }
        }
      }
    }
  }
}
