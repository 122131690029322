@import "../../../../styles/mixins/general";
@import "../../../../components/companyBranding/companyBranding.component.scss";
@import "../../../../styles/mixins/responsive";
@import "../../../../styles/variables";
@import "../../../../styles/itinerary/variables";

@include app-namespace-wrap() {
  .registration_tabs {
    flex-flow: wrap;
    font-family: $branding_font-family--header;
  }

  .registration_sections {
    margin-top: gux(4);

    .nui-field {
      color: $branding_tertiary-contrast;
    }

    .nui-button {
      padding: 0 gux(2);
      background-color: $branding_primary;
      color: $branding_primary-contrast;

      &_label {
        text-transform: uppercase;
      }
    }

    &_back-button.nui-button {
      border: 1px solid $branding_primary;
      background-color: $branding_primary-contrast;
      color: $branding_primary;
    }

    .registration_tabs {
      position: relative;
      background: transparent;
      margin-left: gux(-2);

      &:after {
        position: absolute;
        bottom: 0;
        content: "";
        height: 1px;
        margin-left: gux(2);
        border-bottom: 1px solid #979797;
        width: 100%;
      }

      .nui-tabs_item--selected {
        border-bottom-color: $branding_secondary;
      }
    }

    .nui-card {
      color: inherit;
      background-color: transparent;
      box-shadow: none;

      &:not(:last-of-type) {
        border-bottom: 1px solid #979797;
      }

      &_header {
        padding-left: 0;
        border: none;
      }

      &_title {
        text-transform: capitalize;
        font-weight: $font-weight--light;
        font-size: $subheading-font-size;
        line-height: $subheading-line-height;
      }

      .nui-layout {
        border: none;
        padding-top: 0;
        padding-left: 0;
      }
    }

    .nui-anchor {
      color: $branding_secondary;
    }

    .nui-text,
    .nui-toggle-input-base_label,
    .nui-toggle-input-base_label-text {
      color: inherit;
    }

    .nui-field_label-text {
      white-space: normal;
    }

    .nui-toggle-input-base--checked,
    .nui-toggle-input-base_input:focus + .nui-toggle-input-base_label {
      .nui-toggle-input-base_control {
        background-color: $branding_primary;
      }
    }

    .nui-badge {
      color: $branding_secondary-contrast;
      background-color: $branding_secondary;
    }

    .nui-file-uploader {
      &_preview-content--text {
        text-align: center;
      }
    }
  }
}
