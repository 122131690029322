@import "../../styles/mixins/general";
@import "../../styles/mixins/nimbus";
@import "../../styles/variables";

@include admin-app-namespace-wrap() {
  .entity-table {
    &_content {
      > .nui-swapable {
        &,
        > .nui-swapable_layer {
          height: 100%;
        }
      }
    }

    &_placeholder,
    &_table {
      min-height: 600px;
    }

    &_filters {
      display: flex;

      > *:not(:first-child) {
        margin-left: gux();
      }
    }

    &_toolbar-group {
      display: flex;
    }

    &_table {
      &-image-cell {
        width: gux(4);
        height: gux(4);
        border-radius: $border-radius--circle;
        background-size: cover;
        background-position: center;
      }

      &-icon-cell {
        .ag {
          &-header-cell-label {
            justify-content: center;
          }
        }
      }

      .ag-cell-wrapper .ag-cell-value,
      .ag-cell-wrapper .ag-cell-value .ag-react-container {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex: auto;
      }
    }

    .nui-toolbar {
      &_row {
        > div > *:not(:first-child) {
          margin-left: gux();
        }
      }
    }

    &_new-button {
      &:disabled {
        cursor: auto;
        pointer-events: auto;
        background: rgba($citrus, $opacity--medium);
        i {
          opacity: $opacity--medium;
        }
      }
    }
  }

  @include app-namespace-wrap() {
    .entity-modal {
      &.nui-modal:not(.nui-modal--fullscreen) .nui-modal_children > div {
        margin: 0 auto;
        max-width: 600px;
      }

      &_delete-button {
        @include position-button--absolute-left;
      }
    }
  }
}
