@import "../../styles/mixins/general";
@import "../../styles/variables";

@include app-namespace-wrap() {
  .checkbox-group {
    &_choice {
      &_list-item:not(:last-child) {
        padding-bottom: gux();
      }
      &_list-item:last-child {
        padding-bottom: 0;
      }
      &_custom-text {
        margin-left: gux(3);
      }
      &_error-text {
        margin-left: gux(3);
        color: $spice;
        font-size: $font-size--caption;
      }
    }
  }
}
