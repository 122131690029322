@import "../../../styles/mixins/general";
@import "../../../styles/mixins/responsive";
@import "../../../styles/variables";

@include app-namespace-wrap() {
  .conferences {
    &_table {
      height: calc(100vh - (#{$height--app-header} + 100px + 64px));

      .nui-table {
        .ag-cell-value {
          flex: 1 1 auto;
        }
      }

      &-filters {
        display: flex;
      }
    }

    &_image-cell {
      width: gux(4);
      height: gux(4);
      border-radius: $border-radius--circle;
      background-size: cover;
      background-position: center;
    }

    &_table-button {
      &:hover,
      &:focus {
        color: $silver;
      }
    }

    &_icon-cell {
      .ag {
        &-header-cell-label {
          justify-content: center;
        }
      }
    }

    .nui-toolbar {
      &_row {
        > div > *:not(:first-child) {
          margin-left: gux();
        }
      }
    }
  }
}
