@import "../../../../../../styles/mixins/general";

@include app-namespace-wrap() {
  $speaker-bio-height: 162px;

  .speaker-edit {
    &_bio {
      .nui-textbox_input {
        min-height: $speaker-bio-height;
      }
    }
  }
}
