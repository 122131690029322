@import "../../../../../styles/mixins/general";
@import "../../../../../styles/itinerary/variables";

$field-label-line-height: 1.7em;

@include app-namespace-wrap() {
  .custom-questions {
    &_section {
      .nui-field {
        margin-bottom: gux(4);
      }

      .nui-field_children {
        display: block;
      }

      .nui-field_label-text {
        font-size: $panel-title-font-size;
        line-height: $field-label-line-height;
      }

      .nui-radio-button {
        display: block;
        margin: 0;
        padding: gux(1) 0;
      }

      .nui-checkbox {
        display: block;
        margin: 0;
        padding: gux(1) 0;
      }

      .checkbox-group_custom-answer-textbox {
        margin-left: gux(3);
      }

      #otherField {
        margin-top: -3em;
        margin-left: gux(3);
      }
    }

    &_footer {
      margin-top: gux(2);
    }

    a {
      color: $branding_secondary;
      text-decoration: none;
      &:focus,
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
