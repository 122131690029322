@import "../../../../../../styles/mixins/general";
@import "../../../../../../styles/variables";
@import "../../../../../../../node_modules/@q4/nimbus-ui/dist/_mixins.scss";

@include app-namespace-wrap() {
  .email-configuration-form {
    .nui-field .nui-tooltip {
      max-width: 360px;

      &--extra-padding {
        width: fit-content;
        padding: gux(0.5) gux(1.25);
      }
    }

    .nui-field {
      &_label-text {
        display: inline-block;
        overflow: visible;
      }
    }

    .nui-info-icon {
      &--default {
        margin-left: gux(0.5);
      }
      &_button {
        background: $ghost;
      }
      &_icon {
        color: $slate;
      }
    }
  }
}
