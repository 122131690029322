@import "../../../../../../node_modules/@q4/nimbus-ui/dist/variables";
@import "../../../../../styles/mixins/general";
@import "../../../../../styles/mixins/responsive";
@import "../../../../../styles/mixins/text";
@import "../../../../../styles/variables";
@import "../../../../../styles/itinerary/variables";

@include app-namespace-wrap() {
  .dial-in-instructions {
    &_header .itinerary_subheading {
      margin-top: 0;
      display: inline-block;
    }

    &_content {
      white-space: pre-wrap;
      font-size: $conference_font-size--base;
      line-height: $info-line-height;
      margin-top: gux(3);

      & > ol,
      > ul {
        padding-left: gux(2);
      }
    }
  }
}
