@import "../../../../../../../styles/mixins/general";
@import "../../../../../../../styles/mixins/responsive";
@import "../../../../../../../styles/variables";
@import "../../../../../../../styles/icons/variables";
@import "../../../../../../../styles/itinerary/variables";

@include app-namespace-wrap() {
  .speaker-details {
    position: relative;
    margin-top: -1px;

    &_section {
      background-color: $branding_secondary_background;
    }

    .section {
      &_inner {
        padding: (gux(8) + 1) 0 gux(9);
      }
    }

    &_inner {
      position: relative;
    }

    &_item {
      display: flex;
    }

    &_column {
      & + .speaker-details_column {
        margin-left: gux(6);
      }
    }

    &_name {
      font-size: $speaker-name-font-size;
      font-weight: $speaker-name-font-weight;
      line-height: $speaker-name-line-height;
      margin: 0 0 gux(0.5);
    }

    &_title,
    &_company {
      font-size: $speaker-heading-font-size;
      font-weight: $font-weight--light;
      line-height: $speaker-heading-line-height;
    }

    &_company {
      margin: 0 0 gux(1);
    }

    &_title {
      margin: 0 0 gux(2);
    }

    &_bio {
      font-size: $speaker-bio-font-size;
      line-height: $speaker-bio-line-height;
      margin: 0;
      white-space: pre-wrap;
    }

    &_image {
      width: 220px;
      height: 260px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    &_contact {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid rgba($white, $opacity--subtle);

      &-label {
        display: block;
        font-size: $speaker-contact-label-font-size;
        letter-spacing: 1px;
        line-height: $speaker-contact-label-line-height;
        text-transform: uppercase;
        opacity: $opacity--medium;
        margin-bottom: gux(1.5);
      }

      &-list {
        display: flex;
      }

      &-anchor {
        font-size: $speaker-contact-anchor-font-size;
        color: rgba($branding_secondary_background-contrast, $opacity--subtle);
        transition: color $transition-duration--default $transition-timing-function--default;

        &:not(:first-child) {
          margin-left: gux(2);
        }

        &:focus,
        &:hover {
          color: $branding_secondary;
          text-decoration: none;
        }
      }

      &--tablet {
        display: none;
        margin-top: gux(4);
        padding-top: gux(3);

        .speaker-details_contact {
          &-label {
            margin-bottom: gux(3);
          }

          &-list {
            justify-content: center;
          }

          &-anchor {
            font-size: $speaker-contact-anchor-font-size--mobile;

            &:not(:first-child) {
              margin-left: gux(4);
            }
          }
        }
      }
    }

    &_close {
      position: absolute;
      top: 0;
      right: 0;
      width: 20px;
      height: 20px;
      border-radius: $border-radius--circle;
      border: 1px solid $white;
      line-height: 20px;
      font-size: $speaker-close-icon-font-size;
      text-align: center;
      cursor: pointer;

      i {
        transition: opacity $transition-duration--default $transition-timing-function--default;
      }

      &:focus {
        outline: none;
      }

      &:focus,
      &:hover {
        i {
          opacity: $opacity--medium;
        }
      }
    }

    .nui-collapsable {
      &_content {
        background-color: $black;
      }
    }

    @include tablet-and-below() {
      text-align: center;

      &_item {
        flex-direction: column;
        align-items: center;
      }

      &_column {
        &:not(:last-child) {
          margin-bottom: gux(3);
        }

        & + .speaker-details_column {
          margin-left: 0;
        }
      }

      &_bio {
        margin-top: gux(3);
      }

      &_contact {
        display: none;

        &--tablet {
          display: block;
        }
      }

      &_close {
        top: -((gux(8) + 1) / 2) - gux();
        left: calc(50% - #{gux()});
        border: none;
        font-size: $speaker-close-icon-font-size--mobile;
        color: $branding_secondary;

        i {
          display: block;
          transform: rotate(180deg);

          &:before {
            font-family: $font-family--icon;
            content: $q4i-caret-down-4pt;
          }
        }
      }

      .section {
        &_inner {
          padding: (gux(8) + 1) 0 gux(5);
        }
      }
    }
  }
}
