@import "../../../styles/mixins/general";
@import "../../../styles/variables";
@import "../../../components/companyBranding/companyBranding.component.scss";
@import "../../../styles/itinerary/_variables.scss";
@import "../../../styles/mixins/responsive";
@import "../../../styles/mixins/logo";

@include public-app-namespace-wrap() {
  background: $branding_tertiary;
}

@include app-namespace-wrap() {
  $width--login: 250px;
  $width--content: 1280px;
  $disclaimer-border: 1px solid rgba($white, 0.5);

  %font-color {
    color: $branding_tertiary-contrast;
  }

  .login {
    background-color: $dark-slate;
    background-size: cover;
    background-position: center;
    transition: background $transition-duration--default $transition-timing-function--default;
    font-family: $conference_font-family--base;
    font-size: $conference_font-size--base;
    line-height: $conference_line-height--base;

    &,
    .nui-text {
      font-family: $conference_font-family--base;
    }

    h1,
    h2 {
      &,
      &.nui-text {
        font-family: $conference_font-family--header;
        @extend %conference_font-color--header;
      }
    }

    &_placeholder {
      .nui-placeholder-content {
        &_icon,
        &_inner {
          @extend %font-color;
        }

        &_inner {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: auto;
        }

        &_icon {
          font-size: 44px;
        }

        &_title {
          max-width: $width--content;
          font-size: $font-size--display-large;
          line-height: $line-height--large;
          margin-bottom: gux(4);
        }

        &_subtitle {
          max-width: none;
        }

        &_actions {
          margin: gux(3) 0 gux(2);
        }

        &_caption a {
          @extend %font-color;
          text-decoration: none;
          letter-spacing: $letter-spacing--small;
        }
      }
    }

    &_content {
      white-space: break-spaces;
    }

    &_credentials {
      margin: 0 auto;
    }

    &_content-row:not(:first-of-type) {
      padding-top: gux(2);
    }

    .login_message {
      .nui-toast {
        min-height: auto;

        &_indicator {
          min-width: gux(4.5);
        }

        &_close {
          display: none;
        }

        &_message {
          padding: gux(1.5);
        }
      }
    }

    &_disclaimer {
      max-width: $width--content;
      padding-bottom: gux(2);
      border-bottom: $disclaimer-border;

      a {
        @extend %font-color;
      }

      &-title {
        display: flex;
        align-items: center;
        font-weight: $font-weight--light;
        margin-top: gux(5);
        margin-bottom: gux(2);

        &::before,
        &::after {
          content: "";
          flex: 1;
          border-bottom: $disclaimer-border;
        }

        &:not(:empty)::before {
          margin-right: gux(2);
        }

        &:not(:empty)::after {
          margin-left: gux(2);
        }
      }

      &-content {
        text-align: left;
        font-size: $font-size--caption;
        line-height: $line-height--caption;
      }
    }

    .nui-placeholder-content_image {
      margin-bottom: gux(4);
      max-width: none;
      width: auto;
      @include logo-style;
    }

    .nui-anchor--white {
      &,
      &:visited {
        @extend %font-color;
      }
    }
  }
}
