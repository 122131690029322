@import "src/styles/mixins/general";
@import "src/styles/mixins/responsive";
@import "src/styles/mixins/text";
@import "src/styles/variables";

@include app-namespace-wrap() {
  &,
  &_wrapper {
    height: 100vh;
    overflow: hidden;
  }

  &_wrapper {
    display: flex;
    align-content: stretch;
  }

  &_navigation {
    flex-shrink: 0;
  }

  &_inner {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-width: 0;

    @include phone() {
      width: 100vw;
    }
  }

  &_view {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  // Nimbus UI Overrides
  // TODO: Move some of these to nimbus
  .nui {
    &-textbox {
      &--textarea {
        padding: 0;

        .nui-textbox_input {
          height: 100%;
          padding: gux();
          line-height: $line-height--paragraph;
          resize: vertical;
        }
      }
    }

    &-message {
      &_content {
        padding-left: gux(8);
        padding-right: gux(8);
      }
    }

    &-file-uploader {
      &_dropzone {
        width: 100%;
      }
    }
  }

  .sr-only {
    @include sr-only();
  }
}
