@import "../../../../../styles/mixins/general";
@import "../../../../../styles/variables";
@import "../../../../../styles/itinerary/variables";

@include app-namespace-wrap() {
  $panel-width: 250px;
  $panel-height: 300px;

  .panel {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: stretch;
    width: $panel-width;
    height: $panel-height;
    padding: gux(5) gux(3);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;

    & + .panel {
      margin-left: gux(4);
    }

    &:focus {
      outline: none;
    }

    &:after,
    &_action,
    &_indicator,
    &_gradient {
      position: absolute;
      pointer-events: none;
    }

    &:after,
    &_gradient {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &:after,
    &_action,
    &_indicator {
      transition: opacity $transition-duration--default $transition-timing-function--default;
    }

    &:after,
    &_action {
      opacity: 0;
    }

    &:after {
      content: "";
      border: 5px solid $branding_secondary;
    }

    &_action {
      right: gux(4);
      bottom: gux(2);
      left: gux(3);
      text-align: center;
      overflow: hidden;

      &-wrap,
      &-label {
        position: relative;
      }

      &-wrap {
        transition-property: color;
        z-index: $z-index--base;

        &,
        &:before,
        &:after {
          transition: $transition-duration--default $transition-timing-function--default;
        }

        &:before,
        &:after {
          position: absolute;
          top: calc(50% - 1.5px);
          content: "";
          width: $panel-width / 2;
          height: 3px;
          background-color: $branding_secondary;
          transition: $transition-duration--default $transition-timing-function--default;
        }

        &:before {
          right: 0;
          transition-property: right;
        }

        &:after {
          left: 0;
          transition-property: left;
        }
      }

      &-label {
        z-index: $z-index--base + 1;
      }
    }

    &_gradient {
      background: linear-gradient(
        to bottom,
        rgba($branding_secondary_background_rgb, $opacity--low) 0%,
        rgba($branding_secondary_background_rgb, $opacity--high) 65%,
        rgba($branding_secondary_background_rgb, 0.85) 100%
      );
      z-index: $z-index--base;
    }

    &_indicator {
      opacity: 0;
      top: 0;
      right: 0;
      height: gux(5);
      padding: 0 gux(2);
      background-color: $branding_secondary;
      font-size: $panel-indicator-font-size;
      font-weight: $panel-indicator-font-weight;
      line-height: gux(5);
    }

    &:after,
    &_action,
    &_category,
    &_title,
    &_detail,
    &_indicator {
      z-index: $z-index--base + 1;
    }

    &_category,
    &_title,
    &_detail {
      white-space: normal;
    }

    &_category,
    &_title {
      font-weight: $font-weight--bold;
    }

    &_category {
      text-transform: uppercase;
      font-size: $panel-category-font-size;
      line-height: $panel-category-line-height;
    }

    &_title {
      font-size: $panel-title-font-size;
      line-height: $panel-title-line-height;
      margin-bottom: gux(0.5);
    }

    &_detail {
      font-size: $panel-details-font-size;
      line-height: $panel-details-line-height;

      & + .panel_detail {
        margin-top: gux(0.5);
      }
    }

    &:hover:not(.panel--selected),
    &:focus:not(.panel--selected) {
      .panel {
        &_action {
          opacity: 1;

          &-wrap {
            &:before {
              right: calc(100% + #{gux()});
            }

            &:after {
              left: calc(100% + #{gux()});
            }
          }
        }
      }
    }

    &--selected,
    &--with-indicator {
      &:after {
        opacity: 1;
      }
    }

    &--selected {
      .panel {
        &_action {
          opacity: 1;

          &-wrap {
            color: transparent;

            &:before {
              right: 0;
            }

            &:after {
              left: 0;
            }
          }
        }
      }
    }

    &--with-indicator {
      .panel {
        &_indicator {
          opacity: 1;
        }
      }
    }
  }
}
