@import "../../../styles/mixins/general";
@import "../../../styles/variables";
@import "../../../styles/itinerary/variables";
@import "../../../components/companyBranding/companyBranding.component.scss";
@import "../../../styles/mixins/responsive";

@include app-namespace-wrap() {
  $form--width: 1060px;
  $form-padding: gux(4);

  .registration {
    position: relative;
    color: $branding_tertiary-contrast;
    font-family: $conference_font-family--base;
    font-size: $conference_font-size--base;
    line-height: $conference_line-height--base;
    padding: $form-padding;
    background-color: $branding_tertiary;

    &,
    .nui-select,
    .nui-pagination,
    .nui-text {
      font-family: $conference_font-family--base;
      @extend %conference_font-color--base;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      &,
      &.nui-text {
        font-family: $conference_font-family--header;
        @extend %conference_font-color--header;
      }
    }

    &_splash {
      height: 100vh;
      background-size: cover;
      background-position: center;
      transition: background-image $transition-duration--default $transition-timing-function--default;
      animation: fade-in $transition-duration--fast $transition-timing-function--default;
      pointer-events: none;
      z-index: $z-index--base;

      &,
      &-gradient {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        background-image: linear-gradient(
          to top,
          $branding_tertiary 0,
          rgba($branding_secondary_background_rgb, 0.3) 75%,
          rgba($branding_secondary_background_rgb, 0.85) 100%
        );

        &_overlay {
          height: 100vh;
          background-size: cover;
          background-position: center;
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          background-image: linear-gradient(to top, $branding_tertiary calc(100% - 550px), transparent 100%);
        }
      }

      &-gradient {
        top: 0;
        bottom: 0;
      }
    }

    &_link {
      font-size: $font-size--caption;
      font-weight: $font-weight--light;
      margin-bottom: gux(4);
      cursor: pointer;

      span {
        margin-right: gux(0.5);
      }

      i {
        color: $branding_secondary;
      }
    }

    &_content {
      position: relative;
      z-index: 1;
    }

    &_form {
      max-width: $form--width;
      width: 100%;
      margin: auto;
      border-radius: $border-radius--small;
    }

    &_footer {
      position: relative;
      text-align: center;
      margin: gux(5) 0 0;
      padding: gux(10) 0;
      border-top: 1px solid #54565a;
      border-bottom: 1px solid #54565a;

      h2.nui-text {
        font-size: $subheading-font-size;
        line-height: $subheading-line-height;
        font-weight: $font-weight--light;
        margin-bottom: gux(2);
      }
    }

    &_closed-text {
      font-size: 1.85em;
      line-height: 1.35em;
      font-weight: $font-weight--light;
    }

    &_layer {
      &:first-child {
        margin-top: gux(4);
      }
    }

    .nui-field {
      color: $branding_tertiary-contrast;
    }

    .nui-button {
      background-color: $branding_primary;
      color: $branding_primary-contrast;
    }

    &_back-button.nui-button {
      border: 1px solid $branding_primary;
      background-color: $branding_primary-contrast;
      color: $branding_primary;
    }

    .nui-card {
      color: inherit;
      background-color: transparent;
      box-shadow: none;

      &:not(:last-of-type) {
        border-bottom: 1px solid #979797;
      }

      &_header {
        padding-left: 0;
        border: none;
      }

      &_title {
        text-transform: capitalize;
        font-weight: $font-weight--light;
        font-size: $subheading-font-size;
        line-height: $subheading-line-height;
      }

      .nui-layout {
        border: none;
        padding: 0;
      }
    }

    .nui-anchor {
      color: $branding_secondary;
    }

    .nui-text,
    .nui-toggle-input-base_label,
    .nui-toggle-input-base_label-text {
      color: inherit;
    }

    .nui-field_label-text {
      white-space: normal;

      > a {
        color: $branding_secondary;
        text-decoration: none;
        &:focus,
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .nui-toggle-input-base--checked,
    .nui-toggle-input-base_input:focus + .nui-toggle-input-base_label {
      .nui-toggle-input-base_control {
        background-color: $branding_primary;
      }
    }

    .nui-checkbox {
      .nui-toggle-input-base {
        &_control {
          background-color: $branding_tertiary-contrast;
        }

        &_input:focus + .nui-toggle-input-base_label .nui-toggle-input-base_control {
          background-color: $branding_tertiary-contrast;
        }
      }

      &.nui-toggle-input-base--checked {
        .nui-toggle-input-base {
          &_control {
            background-color: $branding_primary;

            .nui-checkbox_icon {
              color: $branding_primary-contrast;
            }
          }

          &_input:focus + .nui-toggle-input-base_label .nui-toggle-input-base_control {
            background-color: $branding_primary;
          }
        }
      }
    }

    .nui-badge {
      color: $branding_secondary-contrast;
      background-color: $branding_secondary;
    }

    .nui-file-uploader {
      &_preview-content--text {
        text-align: center;
      }
    }
  }
}
