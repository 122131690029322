@import "../../../../../../../../../node_modules/@q4/nimbus-ui/dist/variables";
@import "../../../../../../../../../node_modules/@q4/nimbus-ui/dist/mixins";
@import "../../../../../../../../styles/mixins/responsive";
@import "../../../../../../../../styles/mixins/general";
@import "../../../../../../../../styles/mixins/nimbus";

$slot-border: 1px solid $light-grey;

@include admin-app-namespace-wrap() {
  .timeslot-view {
    padding: gux(3);
    border: $slot-border;
    border-bottom: none;
    width: 100%;

    &:last-child {
      border-bottom: $slot-border;
    }

    &--conflict {
      border-color: $cherry;

      & + .timeslot-view {
        border-top-color: $cherry;
      }

      &:last-child {
        border-color: $cherry;
      }
    }

    &_text {
      min-width: gux(18);
    }

    &_controls {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      font-size: $font-size--subheader;
    }

    &_error {
      padding-left: gux(2);
      display: flex;
      flex-grow: 1;
      align-items: center;

      & > i {
        height: gux(2);
        width: gux(2);
        font-size: gux(1.25);
        line-height: $line-height--base;
        background-color: $cherry;
      }

      & > span {
        color: $cherry;
        font-size: $font-size--base;
        line-height: $line-height--base;
      }
    }

    &_card {
      border-radius: 0;
      box-shadow: none;
    }

    &_buttons {
      flex-shrink: 0;

      .nui-button {
        margin-left: gux(2);
      }
    }
  }
}
