@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?l6409j') format('woff2'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?l6409j') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?l6409j') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?l6409j##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="q4i-"], [class*=" q4i-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.q4i-search-2pt {
  &:before {
    content: $q4i-search-2pt; 
  }
}
.q4i-chat-fill {
  &:before {
    content: $q4i-chat-fill; 
  }
}
.q4i-cog-4pt {
  &:before {
    content: $q4i-cog-4pt; 
  }
}
.q4i-import-4pt {
  &:before {
    content: $q4i-import-4pt; 
  }
}
.q4i-paperclip-4pt {
  &:before {
    content: $q4i-paperclip-4pt; 
  }
}
.q4i-send-4pt {
  &:before {
    content: $q4i-send-4pt; 
  }
}
.q4i-menu {
  &:before {
    content: $q4i-menu; 
  }
}
.q4i-linkedin {
  &:before {
    content: $q4i-linkedin; 
  }
}
.q4i-email {
  &:before {
    content: $q4i-email; 
  }
}
.q4i-facebook {
  &:before {
    content: $q4i-facebook; 
  }
}
.q4i-instagram {
  &:before {
    content: $q4i-instagram; 
  }
}
.q4i-twitter {
  &:before {
    content: $q4i-twitter; 
  }
}
.q4i-youtube {
  &:before {
    content: $q4i-youtube; 
  }
}
.q4i-question-mark-2pt {
  &:before {
    content: $q4i-question-mark-2pt; 
  }
}
.q4i-surveillance-4pt {
  &:before {
    content: $q4i-surveillance-4pt; 
  }
}
.q4i-hidden-4pt {
  &:before {
    content: $q4i-hidden-4pt; 
  }
}
.q4i-link-chain-4pt {
  &:before {
    content: $q4i-link-chain-4pt; 
  }
}
.q4i-time-2pt {
  &:before {
    content: $q4i-time-2pt; 
  }
}
.q4i-time-4pt {
  &:before {
    content: $q4i-time-4pt; 
  }
}
.q4i-checkmark-2pt {
  &:before {
    content: $q4i-checkmark-2pt; 
  }
}
.q4i-checkmark-4pt {
  &:before {
    content: $q4i-checkmark-4pt; 
  }
}
.q4i-clone-duplicate-2pt {
  &:before {
    content: $q4i-clone-duplicate-2pt; 
  }
}
.q4i-clone-duplicate-4pt {
  &:before {
    content: $q4i-clone-duplicate-4pt; 
  }
}
.q4i-note-2pt {
  &:before {
    content: $q4i-note-2pt; 
  }
}
.q4i-note-4pt {
  &:before {
    content: $q4i-note-4pt; 
  }
}
.q4i-utility-4pt {
  &:before {
    content: $q4i-utility-4pt; 
  }
}
.q4i-close-2pt {
  &:before {
    content: $q4i-close-2pt; 
  }
}
.q4i-close-4pt {
  &:before {
    content: $q4i-close-4pt; 
  }
}
.q4i-arrow-left-2pt {
  &:before {
    content: $q4i-arrow-left-2pt; 
  }
}
.q4i-arrow-left-4pt {
  &:before {
    content: $q4i-arrow-left-4pt; 
  }
}
.q4i-chat-2pt {
  &:before {
    content: $q4i-chat-2pt; 
  }
}
.q4i-chat-4pt {
  &:before {
    content: $q4i-chat-4pt; 
  }
}
.q4i-admin-2pt {
  &:before {
    content: $q4i-admin-2pt; 
  }
}
.q4i-admin-4pt {
  &:before {
    content: $q4i-admin-4pt; 
  }
}
.q4i-activity-2pt {
  &:before {
    content: $q4i-activity-2pt; 
  }
}
.q4i-activity-4pt {
  &:before {
    content: $q4i-activity-4pt; 
  }
}
.q4i-conference-2pt {
  &:before {
    content: $q4i-conference-2pt; 
  }
}
.q4i-conference-4pt {
  &:before {
    content: $q4i-conference-4pt; 
  }
}
.q4i-team-2pt {
  &:before {
    content: $q4i-team-2pt; 
  }
}
.q4i-team-4pt {
  &:before {
    content: $q4i-team-4pt; 
  }
}
.q4i-data-2pt {
  &:before {
    content: $q4i-data-2pt; 
  }
}
.q4i-data-4pt {
  &:before {
    content: $q4i-data-4pt; 
  }
}
.q4i-sync-or-fetch-2pt {
  &:before {
    content: $q4i-sync-or-fetch-2pt; 
  }
}
.q4i-sync-or-fetch-4pt {
  &:before {
    content: $q4i-sync-or-fetch-4pt; 
  }
}
.q4i-q4-team-4pt {
  &:before {
    content: $q4i-q4-team-4pt; 
  }
}
.q4i-q4-team-2pt {
  &:before {
    content: $q4i-q4-team-2pt; 
  }
}
.q4i-home-2pt {
  &:before {
    content: $q4i-home-2pt; 
  }
}
.q4i-home-4pt {
  &:before {
    content: $q4i-home-4pt; 
  }
}
.q4i-undo-2pt {
  &:before {
    content: $q4i-undo-2pt; 
  }
}
.q4i-undo-4pt {
  &:before {
    content: $q4i-undo-4pt; 
  }
}
.q4i-link-2pt {
  &:before {
    content: $q4i-link-2pt; 
  }
}
.q4i-link-4pt {
  &:before {
    content: $q4i-link-4pt; 
  }
}
.q4i-image-2pt {
  &:before {
    content: $q4i-image-2pt; 
  }
}
.q4i-image-4pt {
  &:before {
    content: $q4i-image-4pt; 
  }
}
.q4i-export-2pt {
  &:before {
    content: $q4i-export-2pt; 
  }
}
.q4i-export-4pt {
  &:before {
    content: $q4i-export-4pt; 
  }
}
.q4i-calendar-2pt {
  &:before {
    content: $q4i-calendar-2pt; 
  }
}
.q4i-calendar-4pt {
  &:before {
    content: $q4i-calendar-4pt; 
  }
}
.q4i-edit-2pt {
  &:before {
    content: $q4i-edit-2pt; 
  }
}
.q4i-edit-4pt {
  &:before {
    content: $q4i-edit-4pt; 
  }
}
.q4i-contact-2pt {
  &:before {
    content: $q4i-contact-2pt; 
  }
}
.q4i-contact-4pt {
  &:before {
    content: $q4i-contact-4pt; 
  }
}
.q4i-logo {
  &:before {
    content: $q4i-logo; 
  }
}
.q4i-trashbin-2pt {
  &:before {
    content: $q4i-trashbin-2pt; 
  }
}
.q4i-trashbin-4pt {
  &:before {
    content: $q4i-trashbin-4pt; 
  }
}
.q4i-caret-left-2pt {
  &:before {
    content: $q4i-caret-left-2pt; 
  }
}
.q4i-caret-left-4pt {
  &:before {
    content: $q4i-caret-left-4pt; 
  }
}
.q4i-caret-down-2pt {
  &:before {
    content: $q4i-caret-down-2pt; 
  }
}
.q4i-caret-down-4pt {
  &:before {
    content: $q4i-caret-down-4pt; 
  }
}
.q4i-add-2pt {
  &:before {
    content: $q4i-add-2pt; 
  }
}
.q4i-add-4pt {
  &:before {
    content: $q4i-add-4pt; 
  }
}

