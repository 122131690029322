@import "../../styles/mixins/general";
@import "../../styles/variables";

@include app-namespace-wrap() {
  .registration-forms {
    margin: auto;
    max-width: $max-width--default;

    &_add-section {
      padding-top: gux(3);
      text-align: center;
    }
  }

  .section-header {
    font-weight: $font-weight--normal;
    margin-top: gux(4);
  }
}
