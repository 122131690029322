@import "../../../../../node_modules/@q4/nimbus-ui/dist/variables";
@import "../../../../../node_modules/@q4/nimbus-ui/dist/mixins";
@import "../../../../styles/mixins/general";

@include admin-app-namespace-wrap() {
  .conference-details {
    flex: 1 1;
    position: relative;
    background-color: $white;

    &_title {
      display: flex;

      span:first-child {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .nui-badge {
        display: block;
      }
    }

    &_content {
      padding: gux(4) 0;
      margin-left: gux(-8);
    }

    &_image {
      height: 200px;
      background-color: $gunpowder;
      padding: gux();

      &--loaded {
        @include checkerboard($black-smoke, $slate);
      }

      &-content {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    &_audit {
      display: block;
      padding: gux(2) 0 0;
      color: $silver;
    }

    .nui-grid {
      &--match-height {
        display: flex;

        .nui-grid_col {
          display: inline-flex;
        }
      }
    }

    .nui-card--full-width {
      width: 100%;
    }

    .nui-banner_information {
      .nui-badge {
        margin-left: gux();
      }
    }
  }
}
