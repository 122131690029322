@import "../../styles/mixins/general";
@import "../../styles/mixins/responsive";
@import "../../styles/variables";
@import "../../../node_modules/@q4/nimbus-ui/dist/variables";
@import "../../../node_modules/@q4/nimbus-ui/dist/_mixins.scss";

@include app-namespace-wrap() {
  .presentation-form {
    .nui-day-picker:not(.nui-portal) {
      max-width: none;
    }

    &_custom-checkbox {
      display: flex;
      margin-top: gux(4);

      @include small-tablet() {
        margin-top: 0;
      }
    }

    &_description {
      .nui-textbox_input {
        min-height: 162px;
      }
    }

    &,
    &_suggested-time {
      margin: 0 auto;
      max-width: 600px;
    }

    &_suggested-time {
      &-label {
        margin-left: gux(2);
      }

      &-popover {
        .nui-button_label {
          white-space: nowrap;
        }
      }
    }

    &_agenda {
      display: inline-block;
      overflow: visible;
      @include tooltip-appear();

      .info-bubble_tooltip,
      .info-bubble_tooltip * {
        white-space: normal;
        width: 100%;
      }
    }

    .nui-chip {
      &_label {
        max-width: none;
      }
    }

    h3.nui-text {
      margin-bottom: gux(3);

      &:not(:first-of-type) {
        margin-top: gux();
      }
    }

    &_custom-label {
      .nui-field_label-text {
        overflow: visible;
        display: inline-block;
      }

      .nui-info-icon {
        &--default {
          width: gux(1.5);
          height: gux(1.5);
          margin-left: gux(0.5);
          margin-bottom: gux(0.25);
        }

        .nui-tooltip {
          max-width: fit-content;

          &--extra-padding {
            width: fit-content;
            padding: gux(0.5) gux(1.25);
          }
        }

        &_button {
          width: gux(1.5);
          height: gux(1.5);
          line-height: gux(1.5);
          background: $ghost;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &_icon {
          font-size: gux();
          color: $slate;
        }
      }
    }
  }

  .nui-day-picker-calendar_day--disabled {
    color: $silver;
  }
}
