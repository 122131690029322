@import "../../../../../styles/mixins/general";
@import "../../../../../../node_modules/@q4/nimbus-ui/dist/variables";
@import "../../../../../components/companyBranding/companyBranding.component.scss";

$avatar-size: 50px;

@include app-namespace-wrap() {
  .registration-availability {
    &_attendee-header {
      margin-bottom: gux(2);
    }

    &_attendee-section {
      display: flex;
    }

    &_attendee-avatar {
      position: relative;
      width: $avatar-size;
      height: $avatar-size;
      border-radius: $border-radius--circle;
      overflow: hidden;
      margin-right: gux(2);

      div {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }

    .availability-form {
      &_heading {
        color: $branding_tertiary-contrast;
      }

      &_heading-date {
        font-size: $font-size--title;
        margin-top: gux(4);
        margin-bottom: gux(2);
      }

      &_column {
        &--date {
          font-size: $font-size--title;
          margin-bottom: gux(4);
        }
      }
    }

    .nui-text,
    .nui-toggle-input-base_label,
    .nui-toggle-input-base_label-text {
      color: inherit;
    }

    .nui-toggle-input-base--checked,
    .nui-toggle-input-base_input:focus + .nui-toggle-input-base_label {
      .nui-toggle-input-base_control {
        background-color: $branding_primary;
      }
    }

    &_navigation {
      margin-top: gux(2);

      .nui-button {
        background-color: $branding_primary;
        color: $branding_primary-contrast;
      }
    }

    &_back.nui-button {
      border: 1px solid $branding_primary;
      background-color: $branding_primary-contrast;
      color: $branding_primary;
    }
  }
}
