@import "../../../node_modules/@q4/nimbus-ui/dist/variables";

// Colors
$branding_primary: var(--primary, $white);
$branding_secondary: var(--secondary, $white);
$branding_secondary_background: var(--secondaryBackground);
$branding_secondary_background_rgb: var(--secondaryBackgroundRgb);
$branding_secondary_background-contrast: var(--secondaryBackgroundContrast);
$branding_tertiary: var(--tertiary, $black);
$branding_primary-contrast: var(--primaryContrast, $black);
$branding_secondary-contrast: var(--secondaryContrast, $black);
$branding_tertiary-contrast: var(--tertiaryContrast, $white);
$branding_font-family--header: var(--fontFamilyHeader);
$branding_font-color--header: var(--fontColorHeader);
$branding_font-family--base: var(--fontFamilyBase);
$branding_font-size--base: var(--fontSizeBase);
$branding_font-color--base: var(--fontColorBase);
