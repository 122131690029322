@import "../../styles/mixins/general";
@import "../../styles/variables";
@import "../../styles/mixins/button";

@include app-namespace-wrap() {
  .card-placeholder {
    margin-top: gux(3);

    &_header-icons {
      .nui-button {
        width: gux(2);
        &:hover,
        &:focus {
          color: inherit;
        }
      }

      .rotated-icon {
        display: inline-block;
        transform: rotate(180deg);
      }

      .caret-button {
        font-size: $font-size--subheader;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: inherit;
      }
    }

    &_column-wrapper {
      margin-bottom: gux(0);
      .nui-checkbox {
        margin-bottom: gux(2);
      }
    }

    &_option-field-wrapper {
      width: 100%;
      margin-right: gux(2);
    }

    &_link-button {
      @include link-button;
    }

    &_remove-icon {
      width: $font-size--icon-small;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    &_footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      border-top: 1px solid $light-grey;
      margin-left: gux(-2);
      margin-right: gux(-2);
      padding: gux(2) gux(2) gux(0);

      .nui-checkbox {
        margin-right: gux(3);
      }

      .menu-button {
        color: inherit;
        &:hover {
          color: inherit;
        }
      }
    }
  }
}
