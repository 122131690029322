@import "../../../../../styles/mixins/general";
@import "../../../../../styles/mixins/responsive";
@import "../../../../../styles/variables";
@import "../../../../../styles/itinerary/variables";

@include app-namespace-wrap() {
  .date-select {
    display: inline;
    vertical-align: baseline;

    &_label {
      margin-right: gux(2);
    }

    &_button.nui-button {
      height: auto;
      width: 315px;
      padding: gux();
      border: 1px solid rgba($white, $opacity--subtle);
      border-radius: 0;
      font-size: inherit;
      line-height: inherit;
      font-weight: inherit;

      &:focus,
      &:hover {
        color: $branding_secondary;
        border-color: $branding_secondary;
      }

      &--disabled {
        width: auto;
        padding-left: 0;
        border: none;

        .nui-button {
          &_label {
            opacity: 1;
          }
        }
      }
    }

    &_popover-menu {
      .nui-popover-menu {
        &_inner {
          background-color: $branding_tertiary;
        }
      }

      .date-select_button {
        margin: 0;
      }
    }

    &--open {
      .date-select_button.nui-button {
        color: $branding_secondary;
      }
    }

    &--empty {
      pointer-events: none;
    }

    @include phone() {
      &_button.nui-button {
        height: auto;
        width: auto;
      }
    }
  }
}
