@import "../../../../../../node_modules/@q4/nimbus-ui/dist/variables";
@import "../../../../../styles/mixins/general";
@import "../../../../../styles/variables";
@import "../../../../../styles/itinerary/variables";

@include app-namespace-wrap() {
  .dial-in {
    padding: 0;

    .nui-text {
      font-size: 1.2302em;
      margin-bottom: gux();
      font-weight: $font-weight--normal;

      &.dial-in_subheader {
        font-weight: $font-weight--bold;
        font-size: 1em;
        margin-top: gux(2);
      }
    }

    &_header {
      margin-top: 0;
      font-size: $subheading-font-size;
      line-height: $subheading-line-height;
    }

    &_link {
      color: $branding_secondary;
    }

    &_inner {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &_group {
      display: flex;
      flex-direction: column;
      line-height: $line-height--subheader;
      margin-bottom: gux(3);

      &:not(:last-child) {
        padding-right: gux(2);
      }

      .nui-text {
        font-weight: $font-weight--bold;
      }

      .nui-text:not(:first-of-type) {
        margin-top: gux(3);
      }
    }

    &_tabs {
      background-color: transparent;
      margin-bottom: gux(2);

      .nui-tabs_item--selected,
      .nui-tabs_item:hover,
      .nui-tabs_item:focus {
        color: $branding_secondary;
      }

      .nui-tabs_item--selected {
        border-bottom-color: $branding_secondary;
      }

      .nui-tabs_item:first-child {
        margin-left: 0;
      }
    }
  }
}
