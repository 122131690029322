@import "../../../../../../../../../node_modules/@q4/nimbus-ui/dist/variables";
@import "../../../../../../../../../node_modules/@q4/nimbus-ui/dist/mixins";
@import "../../../../../../../../styles/mixins/general";
@import "../../../../../../../../styles/mixins/nimbus";
@import "../../../../../../../../styles/variables";

@include app-namespace-wrap() {
  .flexible-scheduler {
    & > div {
      margin-bottom: gux(2);
    }
  }

  @include app-namespace-wrap() {
    .flexible-scheduler_modal {
      &-delete-button {
        @include position-button--absolute-left;
      }
    }

    .flexible-scheduler_modal:not(.nui-modal--fullscreen) {
      .nui-modal_container {
        width: 480px;

        .nui-modal_content {
          background-color: $white;

          .nui-modal_children {
            padding-top: gux();

            hr {
              border: solid $light-grey;
              border-width: 1px 0 0;
            }
          }
        }

        .nui-modal_header::after {
          content: "";
          width: calc(100% - #{gux(8)});
          background: $light-grey;
          height: 1px;
          position: absolute;
          left: calc((100% - (100% - #{gux(8)})) / 2);
          top: gux(9.5);
        }
      }
    }
  }
}
