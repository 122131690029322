@import "../../../../../../../../../node_modules/@q4/nimbus-ui/dist/variables";
@import "../../../../../../../../../node_modules/@q4/nimbus-ui/dist/mixins";
@import "../../../../../../../../styles/mixins/general";
@import "../../../../../../../../styles/mixins/nimbus";

@include admin-app-namespace-wrap() {
  .meeting-shell-creator-content {
    min-height: gux(65);
    padding: gux(2);
    margin-bottom: gux(5);
    background-color: $light-grey;
    box-sizing: content-box;

    & > span {
      display: block;
      margin-bottom: gux(2);
    }

    &_date-range {
      min-width: auto;
    }

    .nui-select__control {
      min-width: auto;
    }

    &_button {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      height: auto;
      min-height: gux(5);
      max-width: gux(19.5);
    }
  }
}
