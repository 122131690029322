@import "../../../../../../styles/mixins/general";
@import "../../../../../../styles/mixins/responsive";
@import "../../../../../../styles/variables";
@import "../../../../../../styles/itinerary/variables";
@import "../../../../../../../node_modules/@q4/nimbus-ui/dist/mixins";

@mixin responsive() {
  .section {
    &_inner {
      display: block;
    }

    &_header {
      margin-right: 0;
      margin-bottom: gux(5);
      width: auto;
    }
  }
}

@include app-namespace-wrap() {
  .presentation-theater {
    margin-bottom: gux(4);

    &_title {
      margin-bottom: gux(2);
    }

    &_date {
      margin-bottom: gux();
    }

    &_frame {
      position: relative;
      background-color: $black-smoke;

      .nui-spinner {
        &,
        &_pulse {
          z-index: 0;
        }
      }

      iframe {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }

    .section {
      &_inner {
        display: flex;
        align-items: center;
        padding: gux(6) 0 0;

        @include phone() {
          @include tablet() {
            display: block;
          }
        }
      }

      &_header {
        margin-right: gux(5);
        margin-bottom: 0;
        width: 350px;
        max-width: 350px;

        @include phone() {
          @include tablet() {
            margin-right: 0;
            width: auto;
          }
        }
      }

      &_content {
        flex: 1 0;
        overflow: hidden;
      }
    }

    &_back-button {
      .nui-anchor {
        color: $branding_secondary;
      }
    }

    &_presentation {
      .section_inner {
        padding: 0;
      }
    }

    &_poll-frame {
      display: block;
      visibility: hidden;
      opacity: $opacity--transparent;
      transition: all $transition-duration--default $transition-timing-function--default;

      &--has-height {
        opacity: $opacity--full;
        visibility: visible;
      }
    }

    &_poll-empty {
      background-color: $white;
      border-radius: $border-radius--small;
      padding: gux(3);

      h3.nui-text {
        color: $dark-slate;
        font-weight: $font-weight--bold;
      }
    }

    .speakers .section {
      padding-bottom: gux(13);

      &_header {
        width: auto;
        padding-right: 28px;

        .itinerary_heading {
          font-size: 20px;
          font-weight: 400;
        }
      }
    }

    @include tablet() {
      @include responsive();
    }

    @include phone() {
      @include responsive();
    }
  }
}
