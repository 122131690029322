@import "../../../../../../styles/mixins/general";
@import "../../../../../../styles/variables";
@import "../../../../../../../node_modules/@q4/nimbus-ui/dist/_mixins.scss";

.presentation-vendor-form {
  &_custom-label {
    .nui-field_label-text {
      display: inline-block;
      overflow: visible;
      @include tooltip-appear();
    }

    .info-bubble_badge {
      margin-left: 4px;
    }

    .info-bubble_tooltip {
      white-space: normal;
    }

    .nui-badge--small {
      padding: gux(0.25);
    }
  }

  &_vendor-status {
    .nui-textbox {
      width: 50%;
    }
  }

  &_vendor-status--ok {
    .nui-field_error-message {
      color: black;
    }
    .nui-field_error-indicator {
      background-color: green;
    }
  }

  &_vendor-status--error {
    .nui-field_error-message {
      color: black;
    }
    .nui-field_error-indicator {
      background-color: #f1af0f;
    }
    .nui-textbox {
      border: 1px solid #f1af0f;
    }
    .nui-textbox_input {
      border: none;
    }
  }

  &_verify-button {
    min-width: 112px;
    max-width: 112px;
    margin: 4px 16px;
  }
}
