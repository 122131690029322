@import "../../../../../styles/mixins/general";
@import "../../../../../styles/mixins/responsive";
@import "../../../../../styles/mixins/logo";
@import "../../../../../styles/variables";
@import "../../../../../styles/itinerary/variables";

@include app-namespace-wrap() {
  .lobby-layout {
    font-family: $conference_font-family--base;
    font-size: $conference_font-size--base;
    line-height: $conference_line-height--base;

    &,
    .nui-button,
    .nui-text {
      font-family: $conference_font-family--base;
      @extend %conference_font-color--base;
    }

    .nui-toggle {
      &_slider {
        background-color: $conference_font-color--base;

        &:before {
          background: $branding_secondary;
        }
      }

      &_checkbox:checked ~ .nui-toggle_slider:before {
        background: $branding_secondary;
      }

      &_label-right {
        @extend %conference_font-color--base;
        text-transform: none;
        font-size: $user-action-font-size;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      &,
      &.nui-text {
        font-family: $conference_font-family--header;
        @extend %conference_font-color--header;
      }
    }

    position: relative;
    background-color: $branding_tertiary;

    &_inner {
      position: relative;
      transition: opacity $transition-duration--default $transition-timing-function--default;
      z-index: $z-index--base + 1;
    }

    &_header {
      display: flex;
      padding: gux(6) $padding--horizontal 0;
      justify-content: space-between;
      align-items: center;
    }

    &_logo {
      display: block;
      @include logo-style;
    }

    &_conference-list {
      flex: 1 1 auto;
      margin-left: gux(4);
      padding-left: gux(4);
      border-left: 1px solid rgba($white, $opacity--subtle);
    }

    &_splash {
      height: 100vh;
      background-size: cover;
      background-position: center;
      transition: background-image $transition-duration--default $transition-timing-function--default;
      animation: fade-in $transition-duration--fast $transition-timing-function--default;
      pointer-events: none;
      z-index: $z-index--base;

      &,
      &-gradient {
        position: absolute;
        right: 0;
        left: 0;
        background-image: linear-gradient(
          to top,
          $branding_tertiary 0,
          rgba($branding_secondary_background_rgb, 0.5) 50%,
          rgba($branding_secondary_background_rgb, 0.85) 100%
        );
      }

      &-gradient {
        top: 0;
        bottom: 0;
      }
    }

    // Typography Start

    // #region Nimbus Override Start
    .nui {
      &-button {
        &,
        &:hover {
          background: transparent;
        }

        &:focus,
        &:hover {
          color: $branding_secondary;

          .nui-button_label {
            text-decoration: underline;
          }
        }

        &:after {
          border-color: $branding_secondary;
        }

        &_icon {
          color: $branding_secondary;
        }

        &_label {
          text-transform: none;
        }
      }

      &-tooltip {
        background-color: $branding_secondary;
        white-space: nowrap;
        width: auto;
        max-width: none;

        &:after {
          border-top-color: $branding_secondary;
          border-bottom-color: $branding_secondary;
        }
      }

      &-spinner {
        top: calc(50% - 14px);
        left: calc(50% - 14px);

        &,
        &_pulse {
          width: 28px;
          height: 28px;
        }

        &_pulse {
          border-color: $branding_secondary;
        }
      }
    }
    // #endregion

    @include tablet-and-below() {
      &_inner {
        height: auto;
      }

      &_header {
        flex-wrap: wrap;
      }

      &_logo {
        max-height: gux(5);
      }

      &_conference-list {
        order: 3;
        width: 100%;
        margin-top: gux(2);
        margin-left: 0;
        padding-left: 0;
        border-left: 0;
      }
    }

    @include tablet() {
      &_header {
        padding: gux(4) $padding-tablet--horizontal 0;
      }
    }

    @include phone() {
      &_header {
        padding: gux(3) $padding-phone--horizontal 0;
      }
    }
  }
}
