@import "../../../styles/mixins/general";
@import "../../../styles/mixins/responsive";
@import "../../../styles/variables";
@import "../../../styles/itinerary/variables";

@include app-namespace-wrap() {
  .itinerary {
    &_details,
    &_live-container {
      display: flex;
    }

    &_hero {
      .section {
        &_content {
          @include ie11-flex-align-items-fix();
          display: flex;
          justify-content: space-between;
          align-content: stretch;
        }
      }
    }

    &_details,
    &_live-container {
      flex-direction: column;
      justify-content: center;
      transition: all $transition-duration--default ease-in-out;
    }

    &_details {
      padding-right: gux(10);
      overflow: hidden;
    }

    &_live-container {
      max-width: 35%;
    }

    // Typography Start
    &_title {
      margin-bottom: gux(4);
      font-size: $title-font-size;
      line-height: $title-line-height;
      font-weight: $title-font-weight;

      span {
        color: $branding_secondary;
      }
    }

    &_heading {
      margin: 0;
      font-size: $heading-font-size;
      line-height: $heading-line-height;
      font-weight: $heading-font-weight;

      & + .itinerary_subheading {
        &:before {
          content: "/";
          margin: 0 gux(3);
          opacity: $opacity--medium;
        }
      }

      &--fancy {
        position: relative;

        &:before {
          content: "";
          position: absolute;
          bottom: gux(-2);
          left: 0;
          right: 0;
          height: 2px;
          background-color: $branding_secondary;
        }
      }
    }

    &_subheading {
      font-size: $subheading-font-size;
      line-height: $subheading-line-height;
      font-weight: $subheading-font-weight;
    }

    &_date {
      margin-bottom: gux(3);
      font-size: $date-font-size;
      font-weight: $date-font-weight;

      &,
      i {
        line-height: $date-line-height;
      }

      i {
        color: $branding_secondary;
        margin-right: gux(2);
      }

      span + span:before {
        content: " – ";
      }
    }

    &_description {
      font-size: $description-font-size;
      line-height: $description-line-height;
      font-weight: $description-font-weight;
      white-space: pre-line;
    }

    &_user-action {
      font-size: $user-action-font-size;
      line-height: $user-action-line-height;
      font-weight: $user-action-font-weight;
    }

    // Typography End

    // Nimbus Override Start
    &.nui {
      &-popover-menu.nui-popover {
        position: fixed;
        background: transparent;

        .nui-popover-menu {
          &_inner {
            margin: 0;

            &:before {
              content: none;
            }
          }
        }
      }
    }
    // Nimbus Override End

    @include tablet-and-below() {
      &_details {
        max-width: none;
        padding: 0;
        margin-bottom: gux(3);
      }

      &_hero {
        .section {
          &_inner {
            padding-top: gux(4);
          }

          &_content {
            flex-direction: column;
            max-height: none;
          }
        }
      }

      &_live-container {
        flex: 1 1 auto;
        justify-content: flex-start;
        max-width: 610px;
      }

      &_title {
        font-size: $title-font-size--mobile;
        line-height: $title-line-height--mobile;
      }

      &_description {
        font-size: $description-font-size--mobile;
        line-height: $description-line-height--mobile;
      }

      &_heading {
        & + .itinerary_subheading {
          margin-left: 0;

          &:before {
            content: none;
          }
        }
      }
    }

    @include tablet() {
      &_hero {
        .section {
          &_inner {
            padding-top: gux(4);
          }
        }
      }
    }

    @include phone() {
      &_date {
        i {
          float: left;
        }

        span {
          display: block;

          &:last-child {
            margin-top: gux(0.5);
            padding-left: 18px + 16px;
          }
        }
      }
    }
  }
}
