@import "../../../../../styles/mixins/general";
@import "../../../../../styles/variables";
@import "../../../../../styles/itinerary/variables";

@include app-namespace-wrap() {
  .live-presentation {
    position: relative;
    max-height: 375px;
    box-shadow: 0 2px gux(3) 0 rgba($black, $opacity--medium);
    transition: $transition-duration--default $transition-timing-function--default;
    transition-property: opacity, transform;
    overflow: hidden;

    &:hover {
      transform: scale(1.025);
    }

    &_image {
      display: block;
      max-width: 100%;
    }

    &_label {
      @extend %conference_font-color--base;
      position: absolute;
      bottom: gux(6);
      left: 0;
      height: 50px;
      line-height: 50px;
      background-color: $branding_primary;
      font-size: $live-presentation-label-font-size;

      i,
      span {
        display: inline-block;
      }

      i {
        width: 50px;
        font-style: initial;
        text-align: center;
        transform: scaleX(0.8);

        &:before {
          content: "▶";
        }
      }

      span {
        padding: 0 gux(3);
        background-color: rgba($black, 0.1);
      }
    }
  }
}
