@import "../../../../../../../../../node_modules/@q4/nimbus-ui/dist/variables";
@import "../../../../../../../../../node_modules/@q4/nimbus-ui/dist/mixins";
@import "../../../../../../../../styles/mixins/general";
@import "../../../../../../../../styles/mixins/nimbus";

@include admin-app-namespace-wrap() {
  .timeslot-edit {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    &_buttons {
      padding-top: gux(2.5);
      flex-shrink: 0;
    }

    .nui-grid_col {
      margin-bottom: 0;
    }
  }
}
