@import "../../../../../../../../../node_modules/@q4/nimbus-ui/dist/variables";

$modalHeaderFooterHeight: 300px;
.presentation-manage-attendees-modal {
  &_scrollbar {
    max-height: calc(100vh - #{$modalHeaderFooterHeight});
  }

  & &_subtitle {
    margin-bottom: gux(3);
  }

  .nui-chip_label {
    max-width: 100%;
  }
}
