$phone-width: 480px;
$small-tablet-width: 600px;
$tablet-width: 1024px;
$desktop-width: 1366px;

@mixin phone {
  @media (max-width: $phone-width) {
    @content;
  }
}

@mixin small-tablet {
  @media (max-width: $small-tablet-width) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$phone-width + 1}) and (max-width: #{$tablet-width}) {
    @content;
  }
}

@mixin tablet-and-below {
  @media (max-width: #{$tablet-width}) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: #{$tablet-width + 1}) and (max-width: #{$desktop-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width + 1}) {
    @content;
  }
}
