@import "../../../../../../../node_modules/@q4/nimbus-ui/dist/variables";
@import "../../../../../../styles/mixins/general";
@import "../../../../../../styles/mixins/nimbus";
@import "../../../../../../styles/mixins/responsive";

@include app-namespace-wrap() {
  .additional-information {
    &_conference-description,
    &_registration-description,
    &_supplementary-info,
    &_login-disclaimer {
      .#{$namespace}textbox_input {
        min-height: 100px;
      }
    }
  }
}
