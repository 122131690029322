@import "../../../../../styles/mixins/general";
@import "../../../../../styles/itinerary/variables";

@include app-namespace-wrap() {
  .render-answer {
    padding: gux(2) 0;

    &--individual {
      padding: 0 0 gux(2) 0;
    }

    .q4i-checkmark-4pt {
      vertical-align: middle;
    }

    .array-answer-item {
      display: block;
    }

    a {
      color: $branding_secondary;
      text-decoration: none;
      &:focus,
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
