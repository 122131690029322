@import "../../../../../../styles/variables";
@import "../../../../../../styles/mixins/general";

$disabled-background: rgba(238, 238, 238, 0.5);

@include app-namespace-wrap() {
  .registrants {
    &_copy-button {
      &:disabled {
        cursor: auto;
        pointer-events: auto;
        opacity: $opacity--high;

        &:hover {
          background: $light-grey;
        }
      }
    }
  }
}
