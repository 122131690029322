@import "../../../../../../../styles/mixins/general";
@import "../../../../../../../styles/variables";
@import "../../../../../../../styles/itinerary/variables";

@include app-namespace-wrap() {
  .info {
    &_content {
      opacity: $opacity--medium;
      font-size: $info-font-size;
      font-weight: $info-font-weight;
      line-height: $info-line-height;
      letter-spacing: 0.5px;
      white-space: pre-line;
    }
  }
}
