@import "../../../node_modules//@q4/nimbus-ui/dist/variables";
@import "../../styles/mixins/general";

$header-height: 50px;

@mixin type($header-font-size, $header-line-height) {
  .accordion {
    &_header {
      border-top-left-radius: $border-radius--small;
      border-top-right-radius: $border-radius--small;
      &-row {
        font-size: $font-size--subheader;
        line-height: $line-height--subheader;
        padding-left: gux(1.5);
      }
      &-badges {
        margin-left: gux();
        .badge,
        .button {
          padding: 0 gux();
        }

        .badge + .button {
          margin-left: gux();
        }
      }
    }

    &_header-content,
    &_toggle {
      [class^="q4i-"] {
        padding: 0 gux(2);
        width: auto;
      }
    }

    &_header-content {
      display: flex;
      align-items: center;
    }
  }

  > .nui-collapsable {
    > .nui-collapsable_content {
      border-bottom-left-radius: $border-radius--small;
      border-bottom-right-radius: $border-radius--small;

      > .nui-collapsable_opacity-layer {
        padding: gux(2);
      }
    }
  }

  &.accordion--collapsed {
    > .accordion_header {
      border-radius: $border-radius--small;
    }
  }
}

@mixin theme--default($header-color, $header-background, $contentBorderColor, $contentBorderColorCollapsed) {
  .accordion {
    &_header {
      color: $header-color;
      background: $header-background;
    }
    &_toggle .button {
      color: $header-color;
    }
  }

  &.accordion--disabled {
    .accordion_toggle [class^="q4i-"] {
      color: rgba($header-color, $opacity--low);
    }
  }

  > .nui-collapsable {
    > .nui-collapsable_content {
      background: $white;
      border-right: 1px solid $contentBorderColor;
      border-bottom: 1px solid $contentBorderColor;
      border-left: 1px solid $contentBorderColor;
    }

    &.nui-collapsable--collapsed {
      > .nui-collapsable_content {
        border-right: 1px solid $contentBorderColorCollapsed;
        border-bottom: 1px solid $contentBorderColorCollapsed;
        border-left: 1px solid $contentBorderColorCollapsed;
      }
    }
  }

  &.accordion--collapsed {
    .accordion_header {
      border-bottom: 1px solid $contentBorderColorCollapsed;
    }
  }
}

@mixin theme--dragdrop($color, $tint, $shade, $activeColor) {
  .accordion {
    &_header {
      .accordion_toggle .button:focus {
        color: $activeColor;
      }
    }
  }

  &:focus,
  &--dragging {
    .drag-drop_handle {
      color: $activeColor;
    }
  }
  &--dragging {
    .accordion--collapsed .accordion_content {
      border-color: transparent;
    }
  }
}

.accordion {
  &_header {
    @include ie11-flex-align-items-fix;
    display: flex;
    align-items: center;
    min-height: $header-height;

    &-row {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    &-row,
    &-content {
      flex: 1 1 auto;
      &--medium {
        font-size: gux(2);
      }
    }

    &-action {
      .accordion_toggle {
        transition: transform $transition-duration--default ease-in-out;
        // rotation is styled inline
        display: inline-block;
        cursor: pointer;
        [class^="q4i-"] {
          transition: color $transition-duration--default ease-in-out;
        }
      }
    }
  }

  &--disabled {
    .accordion_toggle {
      cursor: default;
      .button {
        pointer-events: none;
      }
    }
  }

  &--light-grey {
    @include theme--default($light-slate, $light-grey, $soft-grey, $soft-grey);
  }

  &--spice {
    @include theme--default($spice, rgba($spice, 0.2), rgba($spice, 0.2), $spice);
  }

  &--rain {
    @include theme--default($white, $rain, $soft-grey, $steel);
  }

  &--sub-header {
    @include type($font-size--subheader, $line-height--subheader);
  }

  &--dragdrop &--light-grey {
    @include theme--dragdrop($light-slate, $light-grey, $soft-grey, $rain);
  }
}
