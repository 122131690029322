@import "../../../../../../../../../node_modules/@q4/nimbus-ui/dist/variables";
@import "../../../../../../../../../node_modules/@q4/nimbus-ui/dist/mixins";
@import "../../../../../../../../styles/mixins/general";

@include admin-app-namespace-wrap() {
  .day-tabs {
    display: flex;
    padding: gux(1.25) gux() gux(1.25) 0;
    justify-content: space-between;
  }
}
