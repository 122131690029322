@import "../../../../../node_modules//@q4/nimbus-ui/dist/variables";

$rowMargin: gux(2);
$font-size-label: 12px;

.import-confirm {
  &_label {
    color: $silver;
    font-size: $font-size-label;

    &:not(:first-child) {
      margin: $rowMargin 0;
    }
  }

  &_value {
    color: $slate;
    text-transform: none;
  }
}
