@import "../../styles/mixins/general";
@import "../../../node_modules/@q4/nimbus-ui/dist/variables";

$availability-border-color: #c4c4c4;

@include app-namespace-wrap() {
  .availability-form {
    &_table {
      margin-top: gux(8);
    }

    &_timeslots {
      border: 1px solid $availability-border-color;
    }

    &_timeslot {
      padding-left: gux(2);

      &:not(:last-child) {
        border-bottom: 1px solid $availability-border-color;
      }
    }

    &_block {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      position: relative;
    }

    &_block-time {
      flex-grow: 1;
      position: relative;
      line-height: $line-height--paragraph;
      padding: gux(1.5) 0;
    }

    &_block-choice {
      display: flex;
      justify-content: space-evenly;
      align-items: stretch;
    }

    &_block-choice-item {
      text-align: center;
      border-left: 1px solid $availability-border-color;
      padding: gux(1.5) 0;

      .nui-toggle-input-base {
        padding: 0 gux(3);
        vertical-align: middle;
      }
    }

    &_heading-date,
    &_heading-label {
      position: relative;
      font-weight: $font-weight--light;
    }

    &_heading-date {
      text-align: center;
      font-size: $font-size--subheader;
      margin-top: gux(4);
      margin-bottom: gux(2);
    }

    &_heading-label {
      display: flex;
      justify-content: space-between;
      margin-bottom: gux();

      span + span {
        width: 185px;
      }
    }

    &_column {
      margin-bottom: gux();

      &--date {
        text-align: center;
        font-size: $font-size--subheader;
        margin-bottom: gux(4);
      }
    }
  }
}
