@import "variables";
@import "./icons/style";
@import "../../node_modules/@q4/nimbus-ui/dist/variables";

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: $font-family--base;
  font-size: $font-size--base;
  font-weight: $font-weight--normal;
  background-color: $slate;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $light-slate;
}

h1,
h2,
h3 {
  font-weight: $font-weight--light;
}

.#{$namespace}field.#{$namespace}field--label-hidden {
  .#{$namespace}field_label-text {
    visibility: hidden;
  }
}

.#{$namespace}grid_col.#{$namespace}grid_col--row {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  margin-top: gux(-1);
  margin-left: gux(-2);

  > * {
    padding-top: gux();
    padding-left: gux(2);
  }
}

.#{$namespace}modal_title-container {
  width: 100%;

  .#{$namespace}modal_language {
    flex: 1;
    min-width: 0;

    .#{$namespace}modal_subtitle {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
