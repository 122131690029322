@import "../../../../styles/mixins/general";
@import "../../../../../node_modules/@q4/nimbus-ui/dist/variables";
@import "../../../../styles/itinerary/variables";

@include app-namespace-wrap() {
  .registration-summary_policy {
    margin-top: gux(3);
    margin-bottom: gux(4);
    display: flex;
    align-items: flex-start;

    .nui-radio-button {
      margin-top: gux(0.4);
    }
  }
}
