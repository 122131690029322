@import "../../../../../../../../../node_modules/@q4/nimbus-ui/dist/variables";
@import "../../../../../../../../../node_modules/@q4/nimbus-ui/dist/mixins";
@import "../../../../../../../../styles/mixins/general";

$request-list-height: 409px;

@include admin-app-namespace-wrap() {
  .scheduler-filter {
    &_selectors {
      padding: gux() gux(2);
      background-color: $light-grey;
      margin-bottom: gux(2);

      .nui-field:first-child {
        margin-bottom: gux();
      }
    }

    &_tabs {
      .nui-tabs {
        flex-wrap: wrap;

        &_item {
          flex-grow: 1;
          padding: gux(2) gux();
          height: auto;
          background-color: $ghost;

          &--selected {
            color: $steel;
            font-weight: $font-weight--bold;
            border-color: $light-grey;
            background-color: $light-grey;
          }
        }
        &_label {
          text-transform: capitalize;
        }
      }
    }

    &_search-section {
      margin-bottom: gux();

      .nui-grid_col:last-child {
        padding-left: gux();
      }

      .nui-toggle-input-base {
        padding: gux() 0;

        + .nui-toggle-input-base {
          padding-left: gux();
        }

        &:not(.nui-toggle-input-base--checked) {
          &.nui-toggle-input-base--background-white .nui-toggle-input-base_control {
            background-color: $white;
          }
        }
      }
    }

    &_requests {
      padding: gux();
      background-color: $light-grey;
    }

    &_requester-list {
      height: 400px;
    }

    .nui-select__control {
      min-width: auto;
    }
  }

  .request-item {
    &:not(:last-child) {
      margin-bottom: gux();
    }

    &_header-name {
      font-size: $font-size--subheader;
      font-weight: $font-weight--normal;
      line-height: $line-height--subheader;
    }

    &_block {
      padding: gux();
      background-color: $white;
      border: 2px solid $white;
      border-radius: $border-radius--small;
      transition: border-color $transition-duration--fast $transition-timing-function--default;

      &--selected {
        border-color: $ink;
      }
    }

    &_details {
      margin-top: gux();

      .badge:nth-child(n + 2) {
        margin-left: gux();
      }
    }
  }
}
