@import "../../../../node_modules/@q4/nimbus-ui/dist/variables";

$logo-size: 40px;
.fallback {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: $white;
  padding: gux(8) gux(12);

  &_logo {
    color: $q4-blue;
    font-size: $logo-size;
    text-align: right;
  }

  .placeholder-content {
    flex: 1 0 auto;

    &_image {
      max-width: 312px;
      height: 235px;
    }
  }
}
