@import "../../../node_modules/@q4/nimbus-ui/dist/variables";
@import "../../styles/mixins/general";

@include app-namespace-wrap() {
  .color-textbox {
    display: flex;
    justify-content: flex-start;
    align-content: stretch;

    &:focus-within {
      .color-textbox_swatch {
        border-color: $silver;
      }
    }

    &_swatch {
      flex-basis: gux(5);
      border-radius: $border-radius--small 0 0$border-radius--small;
      border: 3px solid $light-grey;
      transition: $transition-duration--default $transition-timing-function--default;
      transition-property: background-color, border-color;
    }

    &_textbox {
      flex: 1 0 auto;
      border-radius: 0 $border-radius--small $border-radius--small 0;
    }
  }
}
