@import "../../../../../../../../styles/mixins/general";
@import "../../../../../../../../../node_modules/@q4/nimbus-ui/dist/variables";

@include app-namespace-wrap() {
  .password-textbox {
    position: relative;

    &_icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: gux(2);
      height: gux(2);
      width: gux(2);

      > i {
        font-size: gux(2);
      }
    }
  }
}
