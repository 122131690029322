@import "../../../../../../node_modules/@q4/nimbus-ui/dist/variables";
@import "../../../../../../node_modules/@q4/nimbus-ui/dist/mixins";

.import-modal {
  .nui-modal {
    &_container {
      transition: width $transition-duration--default ease-in-out;
    }

    &_title-container {
      min-width: 0;
    }

    &_subtitle {
      @include truncate;
      margin-top: gux(1.5);
    }

    &_content {
      padding-top: gux(3);
    }

    &_children {
      transition: padding $transition-duration--default ease-in-out, height $transition-duration--default ease-in-out;
      padding: 0 gux(4) gux(4);
    }
  }
}
