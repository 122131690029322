@import "../../../../../styles/mixins/general";
@import "../../../../../styles/mixins/responsive";
@import "../../../../../styles/variables";
@import "../../../../../styles/icons/variables";
@import "../../../../../styles/itinerary/variables";

@include app-namespace-wrap() {
  $buttun-size: gux(5);
  $buttun-size--small: gux(4);

  .slick {
    &-slider {
      width: 100vw;
      max-width: calc(100% + #{$padding--horizontal * 2});
      margin: gux(-3.5) (-$padding--horizontal);
      padding: gux(3.5) $padding--horizontal;
      overflow: hidden;
    }

    &-list {
      overflow: visible;
    }

    &-track {
      width: 100% !important;
      margin: 0 gux(-4);
      white-space: nowrap;
    }

    &-slide {
      position: relative;
      float: none;
      display: inline-block;
      padding-left: gux(4);
      vertical-align: top;
      z-index: $z-index--base;

      & > div:focus {
        outline: none;
      }
    }

    &-arrow {
      width: $padding--horizontal * 3;
      height: calc(100% + #{gux(7)});
      z-index: $z-index--modal-level-one;

      &:hover {
        &:before {
          opacity: $opacity--high;
        }
      }

      &,
      &:before {
        transition: opacity $transition-duration--default $transition-timing-function--default;
      }

      &:before {
        content: $q4i-caret-left-4pt;
        opacity: 1;
        display: inline-block;
        width: $buttun-size;
        height: $buttun-size;
        color: $black-smoke;
        background-color: $branding_secondary;
        border-radius: $border-radius--small;
        font-size: $font-size--icon;
        font-family: $font-family--icon;
        line-height: $buttun-size;
        vertical-align: middle;
      }
    }

    &-prev {
      left: 0;
      background: linear-gradient(to right, $branding_tertiary 0%, transparent 100%);
      color: $branding_tertiary-contrast;
    }

    &-next {
      right: 0;
      background: linear-gradient(to left, $branding_tertiary 0%, transparent 100%);
      color: $branding_tertiary-contrast;

      &:before {
        transform: scale(-1);
      }
    }

    &-disabled {
      opacity: 0;
      pointer-events: none;
    }

    &-slider--compact {
      padding-bottom: gux(7);

      .slick-arrow:before {
        width: $buttun-size--small;
        height: $buttun-size--small;
        line-height: $buttun-size--small;
      }
    }

    @include tablet {
      &-slider {
        max-width: calc(100% + #{$padding-tablet--horizontal * 2});
        margin: gux(-3.5) (-$padding-tablet--horizontal);
        padding: gux(3.5) $padding-tablet--horizontal;
      }

      &-arrow {
        width: $padding-tablet--horizontal * 3;
      }
    }

    @include phone() {
      &-slider {
        max-width: calc(100% + #{$padding-phone--horizontal * 2});
        margin: gux(-3.5) (-$padding-phone--horizontal);
        padding: gux(3.5) $padding-phone--horizontal;
      }

      &-arrow {
        width: $padding-phone--horizontal * 3;
      }
    }
  }
}
