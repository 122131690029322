@import "../../../../../../../styles/mixins/general";
@import "../../../../../../../styles/mixins/responsive";
@import "../../../../../../../../node_modules/@q4/nimbus-ui/dist/variables";
@import "../../../../../../../components/companyBranding/companyBranding.component.scss";
@import "../../../../../../../styles/itinerary/variables";

@include app-namespace-wrap() {
  .request-meetings {
    margin-bottom: gux(3);

    .nui-select {
      .nui-select__control {
        min-width: 100%;
      }
    }

    &_corporate-profile {
      margin-top: gux(2);
      margin-bottom: gux(6);
    }

    &_corporate-profile-header {
      position: relative;
      overflow-x: hidden;

      &::after {
        content: "";
        width: 100%;
        position: absolute;
        bottom: 0;
        left: gux(3);
        border-bottom: 1px solid $dove-grey;
      }

      .nui-grid_col {
        margin-bottom: gux();
        font-family: inherit;
      }
    }

    &_corporate-profile-row {
      padding: gux(2) 0;
      position: relative;
      display: flex;

      &::after {
        content: "";
        width: calc(100% - #{gux(3)});
        position: absolute;
        bottom: 0;
        left: gux(3);
        border-bottom: 1px solid $dove-grey;
      }

      @include small-tablet() {
        display: inherit;
      }
    }

    &_corporate-profile-info {
      display: flex;
    }

    &_corporate-name,
    &_corporate-ticker {
      margin: 0;
    }

    &_corporate-name {
      font-size: $subheading-font-size;
      line-height: $subheading-line-height;
      font-weight: $font-weight--normal;
      word-break: break-word;
    }

    &_corporate-ticker {
      font-weight: $font-weight--light;
      line-height: $line-height--paragraph;
    }

    &_corporate-profile-logo {
      width: 60px;
      height: 60px;
      background-size: contain;
      background-repeat: no-repeat;
      margin-right: gux(2);
      flex-shrink: 0;
      margin-top: gux();
    }

    &_corporate-attendees {
      margin-top: gux(0.75);
      word-break: break-word;
    }

    &_requests-title {
      margin-bottom: gux(3);

      &.nui-text {
        font-weight: $font-weight--normal;
      }
      &--margin {
        margin-top: gux(12);
      }
    }

    &_request-button {
      width: gux(11.5);
      height: gux(5);
      margin-top: gux(0.75);

      &--requested {
        width: gux(13.5);
      }
    }

    &_request-delete-button {
      position: absolute;
      right: gux(-3);
      color: $branding_primary;
      cursor: pointer;

      @include small-tablet() {
        top: gux(2);
      }
    }

    &_meeting-interest {
      padding-left: 0;

      & > div:last-child {
        margin-top: gux(0.5);
      }
    }
  }
}
