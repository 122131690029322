@import "../../../../../styles/mixins/general";
@import "../../../../../styles/variables";
@import "../../../../../styles/itinerary/variables";

@include app-namespace-wrap() {
  .details {
    display: flex;
    flex-direction: column;

    &_closed-message {
      margin: 2em 0;
      font-size: $heading-font-size;
    }
  }
}
