@import "../../../../../styles/mixins/general";
@import "../../../../../styles/variables";
@import "../../../../../components/companyBranding/companyBranding.component.scss";

@include app-namespace-wrap() {
  $width--base: 330px;
  $margin--base: gux(3);
  $font-size--message: 12px;

  .login-form {
    width: $width--base;
    padding: gux(4);
    background-color: $white;
    border-radius: $border-radius--large;
    color: $light-slate;

    &_instructions {
      span {
        font-weight: $font-weight--bold;
      }
    }

    &_button,
    &_button:hover,
    &_button:focus {
      width: 100%;
      background-color: $branding_primary;
      color: $branding_primary-contrast;
    }

    &_toast-header {
      display: block;
      font-size: $font-size--subheader;
      line-height: $line-height--subheader;
    }

    &_toast-content-row {
      display: block;
    }

    &_message {
      .nui-toast {
        min-height: auto;

        &_message {
          border: 1px solid $citrus;
          border-top-right-radius: $border-radius--small;
          border-bottom-right-radius: $border-radius--small;

          text-align: left;
          padding: gux(1.5) gux();
          line-height: $line-height--base;
          font-size: $font-size--message;
        }
      }
    }

    & > .nui-collapsable:last-of-type .nui-button {
      margin-bottom: 0;
    }

    &_instructions,
    &_button,
    &_message,
    .nui-field {
      margin-bottom: $margin--base;
    }

    .nui-field {
      &_label {
        text-align: left;
      }
    }

    .nui-anchor {
      display: inline-block;
      text-align: center;
    }
  }
}
