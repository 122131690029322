@import "../../../node_modules//@q4/nimbus-ui/dist/variables";

@mixin theme($color, $bgColor: $light-grey) {
  /* IE and EDGE */
  background-color: $bgColor;
  color: $color;

  /* WEBKIT BROWSERS - CHROME, OPERA AND SAFARI */
  &::-webkit-progress-bar {
    background-color: $bgColor;
  }

  &::-webkit-progress-value {
    background-color: $color;
  }

  /* MOZILLA FIREFOX */
  &::-moz-progress-bar {
    background-color: $color;
  }
}

@mixin visual-effects {
  border-radius: $border-radius--medium;
  transition: all $transition-duration--default ease-in-out;
}

@mixin progress-bar {
  @include visual-effects;

  /* WEBKIT BROWSERS - CHROME, OPERA AND SAFARI */
  &::-webkit-progress-bar {
    @include visual-effects;
  }

  &::-webkit-progress-value {
    @include visual-effects;
  }

  /* MOZILLA FIREFOX */
  &::-moz-progress-bar {
    @include visual-effects;
  }
}

.progress-bar {
  /* RESETS */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;

  /* STYLING */
  width: 100%;
  height: 10px;

  @include progress-bar;
  @include theme($rain);

  &--teal {
    @include theme($teal);
  }

  &--spice {
    @include theme($spice);
  }
}
