@import "../variables";
@import "../icons/variables";
@import "../../components/companyBranding/companyBranding.component.scss";

$font-family--icon: $icomoon-font-family;

// Font variables
$conference_font-family--base: $branding_font-family--base;
$conference_font-size--base: $branding_font-size--base;
$conference_line-height--base: 1.2em;

$conference_font-color--header: $branding_font-color--header;
%conference_font-color--header {
  color: $white;
  color: $conference_font-color--header;
}

$conference_font-color--base: $branding_font-color--base;
%conference_font-color--base {
  color: $white;
  color: $branding_font-color--base;
}

$conference_font-family--header: $branding_font-family--header;

// Base Padding
$padding--horizontal: gux(7);
$padding-tablet--horizontal: 40px;
$padding-phone--horizontal: 16px;

$padding--vertical: gux(7);
$padding-tablet--vertical: 60px;
$padding-phone--vertical: 48px;

// Hero Title
$title-font-size: 5.077em;
$title-line-height: 1.121em;
$title-font-weight: $font-weight--light;

// Hero Title - Mobile
$title-font-size--mobile: 4em;
$title-line-height--mobile: 1.155em;

// Hero Date
$date-font-size: 1.385em;
$date-line-height: 1.333em;
$date-font-weight: $font-weight--bold;

// Hero description
$description-font-size: 1.385em;
$description-line-height: 1.666em;
$description-font-weight: $font-weight--normal;

// Hero description - Mobile
$description-font-size--mobile: 1.231em;
$description-line-height--mobile: 1.625em;

// Section Heading
$heading-font-size: 2.769em;
$heading-line-height: 1.389em;
$heading-font-weight: $font-weight--light;

// Section Subheading
$subheading-font-size: 1.538em;
$subheading-line-height: 1.6em;
$subheading-font-weight: $font-weight--light;

// Section Indicator
$indicator-font-size: 0.846em;
$indicator-line-height: 1.364em;
$indicator-font-weight: $font-weight--light;

// User Action (Add to Personal Agenda)
$user-action-font-size: 1em;
$user-action-line-height: 2.308em;
$user-action-font-weight: $font-weight--normal;

// Panel
$panel-indicator-font-size: 1.154em;
$panel-indicator-font-weight: $font-weight--bold;
$panel-category-font-size: 1em;
$panel-category-line-height: 2.308em;
$panel-title-font-size: 1.385em;
$panel-title-line-height: 1.333em;
$panel-details-font-size: 1em;
$panel-details-line-height: 1.385em;

// Info
$info-font-size: 1.077em;
$info-line-height: 1.714em;
$info-font-weight: $font-weight--light;

// Live Presentation
$live-presentation-label-font-size: 1.154em;

// Navigation
$navigation-anchor-font-size: 1.154em;
$navigation-anchor-font-weight: $font-weight--bold;
$navigation-anchor-line-height: 1.333em;
$navigation-anchor-icon-font-size: $navigation-anchor-font-size;
$navigation-anchor-icon-font-weight: $font-weight--normal;
$navigation-icon-font-size: 2em;
$navigation-toggle-font-size: 1.538em;
$navigation-anchor-icon-font-size--mobile: $navigation-toggle-font-size;

// Speaker
$speaker-name-font-size: 2.462em;
$speaker-name-font-weight: $font-weight--light;
$speaker-name-line-height: 1.344em;
$speaker-heading-font-size: 1.385em;
$speaker-heading-line-height: 1.333em;
$speaker-bio-font-size: 1.154em;
$speaker-bio-line-height: 1.8em;
$speaker-contact-label-font-size: 1em;
$speaker-contact-label-line-height: 1.385em;
$speaker-contact-anchor-font-size: 2.154em;
$speaker-contact-anchor-font-size--mobile: 2.462em;
$speaker-close-icon-font-size: 11px;
$speaker-close-icon-font-size--mobile: gux(2);

// Agenda
$agenda-time-font-size: 1.1em;
$agenda-room-font-size: 1em;

// Powered By
$powered-by-title-font-size: 2em;
$powered-by-title-line-height: 1.654em;
$powered-by-anchor-font-size: 1.077em;
$powered-by-anchor-line-height: 1.357em;
$powered-by-anchor-icon-font-size: 1.231em;
