@import "../../../../../../../styles/mixins/general";
@import "../../../../../../../styles/mixins/responsive";
@import "../../../../../../../styles/variables";
@import "../../../../../../../styles/itinerary/variables";

@include app-namespace-wrap() {
  .navigation {
    &_list {
      display: flex;
      align-items: flex-start;
      margin: 0;
      list-style: none;
    }

    &_item {
      &:not(:first-child) {
        margin-left: gux(4);
      }
    }

    &_anchor {
      @extend %conference_font-color--base;
      position: relative;
      font-size: $navigation-anchor-font-size;
      font-weight: $navigation-anchor-font-weight;
      line-height: $navigation-anchor-line-height;
      letter-spacing: 0.5px;
      text-decoration: none;
      transition: color $transition-duration--default $transition-timing-function--default;
      cursor: pointer;

      &:focus,
      &:hover {
        &:before {
          opacity: 1;
        }
      }

      &:focus {
        outline: none;
      }

      &:before {
        content: "";
        position: absolute;
        bottom: gux(-1);
        left: 0;
        right: 0;
        opacity: 0;
        height: 3px;
        background-color: $branding_secondary;
        pointer-events: none;
        transition: opacity $transition-duration--default $transition-timing-function--default;
      }

      &--icon {
        font-size: $navigation-icon-font-size;
        padding-left: gux();

        &:focus,
        &:hover {
          color: $branding_secondary;
        }

        &:before {
          content: none;
        }
      }
    }

    &_toggle {
      width: gux(10);
      height: gux(5);
      margin-right: gux(-5);
      padding-right: gux(5);
      line-height: gux(5);
      font-size: $navigation-toggle-font-size;
      text-align: center;
      cursor: pointer;

      &:focus {
        outline: none;
      }

      &:focus,
      &:hover {
        i:before {
          color: $branding_secondary;
        }
      }

      i:before {
        transition: color $transition-duration--default $transition-timing-function--default;
      }
    }

    &_popover-menu.nui-portal {
      .nui-popover-menu {
        &_inner {
          width: 270px;
          height: calc(100vh - #{gux(8)});
          margin-top: gux(2) !important;
          border-radius: 0;
          background-color: $branding_tertiary;
          box-shadow: 0 0 20px 0 rgba($black, $opacity--medium);

          > *:not(:last-child) {
            border-bottom: 1px solid $black;
          }
        }
      }

      .navigation {
        &_anchor {
          @extend %conference_font-color--base;
          text-align: left;
          height: 50px;
          line-height: 50px;
          margin: 0;
          padding: 0 gux(4);
          border-bottom: 1px solid $branding-secondary;
          text-decoration: none;
          background-color: inherit;
          border-radius: 0;

          &:before {
            top: 0;
            bottom: 0;
            left: 0;
            right: auto;
            width: 3px;
            height: 100%;
          }

          &--icon {
            height: 70px;
            line-height: 70px;
            font-size: $navigation-anchor-icon-font-size;
            font-weight: $navigation-anchor-icon-font-weight;

            .nui-button {
              &_icon,
              &_label {
                vertical-align: middle;
              }

              &_icon {
                font-size: $navigation-anchor-icon-font-size--mobile;
              }
            }
          }
        }
      }
    }

    &_attendee-profile {
      &_popover-menu {
        .nui-popover-menu {
          &_inner {
            > *:not(:last-child) {
              border-bottom: 1px solid $black;
            }

            .q4i-export-4pt {
              transform: rotate(90deg);
            }
          }
        }
      }
    }

    @include phone() {
      &_toggle {
        width: gux(7);
        margin-right: gux(-2);
        padding-right: gux(2);
      }

      &_popover-menu.nui-portal {
        .nui-popover-menu {
          &_inner {
            > *:not(:last-child) {
              border-bottom: 1px solid $black;
            }

            height: calc(100vh - #{gux(8)});
          }
        }
      }
    }
  }
}
