@import "../../styles/mixins/general";
@import "../../styles/variables";
@import "../../../node_modules/@q4/nimbus-ui/dist/_mixins.scss";

@include app-namespace-wrap() {
  $badge-font-size: 9px;

  .speaker-form {
    &_custom-label {
      .nui-field_label-text {
        display: inline-block;
        overflow: visible;
        @include tooltip-appear();
      }

      .info-bubble_tooltip {
        white-space: normal;
      }
    }

    .nui-badge {
      vertical-align: top;
      font-size: $badge-font-size;
      padding: gux(0.25);
    }
  }
}
