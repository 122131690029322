@import "../../../../../../styles/mixins/general";
@import "../../../../../../styles/variables";
@import "../../../../../../../node_modules/@q4/nimbus-ui/dist/_mixins.scss";

@include app-namespace-wrap() {
  .conference-registration-edit {
    .nui-keyline {
      margin: gux(2.5) gux(-2);
      background-color: $light-grey;

      &:first-child {
        margin-top: gux(0);
        margin-bottom: gux(0.5);
        background-color: transparent;
      }
      &:last-child {
        margin-top: gux(1);
        background-color: transparent;
      }
    }

    .nui-day-picker:not(.nui-portal) {
      max-width: none;
    }

    .nui-collapsable_content {
      margin-top: gux(1.5);
    }

    .accordion_header-action {
      background-color: $light-grey;
      padding: gux(1) gux(1.25);
      border-radius: $border-radius--small;
    }

    &_section-error {
      color: $spice;
    }
  }
}
