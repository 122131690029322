@import "../../styles/mixins/general";
@import "../../styles/mixins/text";
@import "../../../node_modules/@q4/nimbus-ui//dist//variables";
@import "../../styles/mixins/q4icons";
@import "../../styles/icons/variables.scss";
@import "../../components/companyBranding/companyBranding.component.scss";

@include app-namespace-wrap() {
  .json-form {
    &_field:last-child {
      margin-bottom: gux(2);
    }

    &_field {
      color: $branding_tertiary-contrast;

      &-nuiRadio {
        .nui-field_children {
          justify-content: flex-start;
        }
      }

      &-nuiImagePreviewValidated .tighten-validation-text {
        .nui-file-uploader_preview-content--text:nth-last-of-type(-n + 2) {
          margin-top: gux(1);
        }
      }

      &-nuiBooleanCheckbox {
        .nui-toggle-input-base_label-text {
          white-space: normal;
        }
      }
    }

    &_field-hidden {
      display: none;
    }

    &_layout {
      .nui-grid {
        .json-form_row-title {
          font-size: $font-size--subheader;
          margin: gux(3) 0;
        }

        &_col:not(.json-form_row-title) {
          margin-bottom: 0;

          & > .json-form_field {
            margin-bottom: 0;
          }
        }

        &:first-child {
          .json-form_row-title {
            margin-top: 0;
          }
        }
      }

      .json-form_layout {
        .nui-grid > .nui-grid_col > .nui-field > .nui-field_label > .nui-text {
          display: none;
        }
      }

      .json-form_layout {
        .field-description {
          max-width: 60%;
        }
      }
    }

    &_navigation {
      margin-top: gux(2);

      .nui-field_error {
        display: inline-block;
        margin-left: gux(3);
        margin-top: 0;

        .nui-field_error-indicator {
          margin-top: gux(0.5);
        }
        .nui-field_error-message {
          font-weight: 300;
          font-size: gux(2);
          line-height: gux(2.5);
        }
      }
    }

    &_back-button.nui-button {
      border: 1px solid $branding_primary;
      background-color: $branding_primary-contrast;
      color: $branding_primary;
    }

    // overrides
    .errors {
      display: none;
    }

    .array-item {
      display: flex;

      .nui-field_children {
        .json-form {
          &_field:not(:first-child):not(.json-form_custom-questions) {
            margin-top: gux(2);
          }
          &_custom-questions {
            margin-top: 0;
            .json-form {
              &_field {
                margin-bottom: 0;
              }
            }
          }
        }
      }

      &-toolbox {
        .btn-group {
          padding-left: gux(2);
        }
      }

      &-remove {
        background: none !important;
        border: none;
        padding: 0 !important;
        cursor: pointer;

        i {
          color: $branding_secondary;
          @include q4-icon-before($q4i-close-4pt);
        }
      }

      &-add {
        button {
          background: none !important;
          border: none;
          padding: 0 !important;
          cursor: pointer;
        }

        i {
          color: $branding_secondary;
          @include q4-icon-before($q4i-add-4pt);

          &::after {
            content: " Add another Attendee";
          }
        }
      }

      .col-xs-9 {
        flex-grow: 1;
      }
    }

    fieldset {
      margin-top: 0;
      margin: 0;
      border: none;
      padding: 0;
      min-width: 100%;

      legend {
        @include sr-only();
      }
    }
  }
}
