@import "../../../../../styles/mixins/general";
@import "../../../../../styles/mixins/responsive";
@import "../../../../../../node_modules/@q4/nimbus-ui/dist/variables";
@import "../../../../../components/companyBranding/companyBranding.component.scss";
@import "../../../../../styles/itinerary/variables";

@include app-namespace-wrap() {
  .meeting-requests {
    margin-bottom: gux(3);

    &_title.nui-text {
      font-weight: $font-weight--normal;
      margin-bottom: gux();
    }

    &_subtitle.nui-text {
      font-weight: $font-weight--normal;
      margin-bottom: gux(3.75);
    }
  }
}
