@import "../../../../../node_modules/@q4/nimbus-ui/dist/variables";

.import-results {
  .import {
    &-info {
      color: $light-slate;
      display: flex;

      &_status {
        flex-basis: 50%;
        border-bottom: 1px solid transparent;
        font-size: $font-size--base;
        text-align: left;

        &--complete {
          color: $teal;
        }

        &--failed {
          color: $spice;
        }
      }

      &_download {
        border-bottom: 1px solid $light-slate;
        cursor: pointer;
      }
    }
  }

  &_progress-bar {
    margin: gux() 0 gux(3);
  }

  .accordion {
    &_header {
      min-height: 45px;

      &-row {
        font-size: $font-size--subheader;
        line-height: $line-height--base;
        color: $light-slate;
        padding: gux(1.5) gux(3);
        border-bottom: 1px solid $soft-grey;
      }
    }

    > .nui-collapsable > .nui-collapsable_content > .nui-collapsable_opacity-layer {
      padding: 0;
    }
  }
}
