@import "../../../../../styles/mixins/general";
@import "../../../../../styles/variables";

@include app-namespace-wrap() {
  .csv-upload {
    display: inline-block;

    &_button {
      line-height: normal;
    }

    & + .nui-button {
      margin-left: gux();
    }

    &_input {
      display: none;
    }
  }
}
