@import "../variables";

@mixin pagination-block {
  &.nui-pagination {
    align-items: center;
    justify-content: center;
    font-family: $font-family--base;
  }

  .nui-pagination {
    &_actions {
      color: red;
      &--pages {
        border-radius: $border-radius--small 0 0 $border-radius--small !important;
        border-top: 1px solid $ash;
        border-bottom: 1px solid $ash;
        border-left: 1px solid $ash;
        border-right: 0;
      }

      &--page-sizes {
        border-radius: 0 $border-radius--small $border-radius--small 0;
        border-top: 1px solid $ash;
        border-bottom: 1px solid $ash;
        border-right: 1px solid $ash;
        border-left: 0;
      }

      .nui-pagination_action {
        padding: 0;

        &--next {
          border-left: 1px solid $ash;
          border-right: 1px solid $ash;

          .nui-pagination_link {
            color: $dark-slate;

            &:focus {
              color: $dark-slate;
            }
          }

          &.nui-pagination_action--disabled {
            .nui-pagination_link {
              color: $silver;
            }
          }
        }

        &--prev {
          border-right: 1px solid $ash;

          .nui-pagination_link {
            color: $dark-slate;

            &:focus {
              color: $dark-slate;
            }
          }

          &.nui-pagination_action--disabled {
            .nui-pagination_link {
              color: $silver;
            }
          }
        }

        &--active {
          .nui-pagination_link {
            background: $titanium;
            color: $white;
            border-radius: $border-radius--small;
          }
        }
      }

      .nui-pagination_link {
        padding: gux(0.5) gux();
        color: $dark-slate;
      }
    }
  }
}
