@mixin q4-icon-defaults() {
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin q4-icon-before($name) {
  @include q4-icon-defaults();

  &:before {
    font-family: "q4icons";
    content: unquote('"#{ $name }"');
  }
}

@mixin q4-icon-after($name) {
  @include q4-icon-defaults();

  &:after {
    font-family: "q4icons";
    content: unquote('"#{ $name }"');
  }
}
