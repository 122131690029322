@import "../../../../../styles/mixins/general";
@import "../../../../../../node_modules/@q4/nimbus-ui/dist/variables";
@import "../../../../../styles/itinerary/variables";

$color-checkmark: $branding_secondary;
$avatar-size: 50px;
$font-size--small-badge: 9px;

@include app-namespace-wrap() {
  .registration-summary {
    color: inherit;

    &_block {
      display: flex;
      margin-bottom: gux(1);

      &-section:first-child:not(:only-child) {
        padding-right: gux(2);
      }
    }

    &_attendee-section {
      display: flex;

      &:not(:last-child) {
        margin-bottom: gux(2);
      }
    }

    &_attendee-avatar {
      position: relative;
      width: $avatar-size;
      height: $avatar-size;
      border-radius: $border-radius--circle;
      overflow: hidden;
      margin-right: gux(2);

      div {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }

    &_attendee-badges {
      .nui-badge {
        font-size: $font-size--small-badge;
        display: inline-block;
        padding: 0 gux(1.25);
        border-radius: $border-radius--large;

        &:last-child:not(:first-child) {
          margin-left: gux();
        }
      }
    }

    &_details {
      img {
        max-width: 200px;
        margin-bottom: gux();
      }
    }

    &_availabilities {
      .registration-summary_block-section:first-child {
        width: 200px;
      }
    }

    .q4i-checkmark-4pt {
      padding-right: gux();
      color: $color-checkmark;
    }
  }
}
