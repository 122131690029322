@import "../../../../../../../../../node_modules/@q4/nimbus-ui/dist/variables";
@import "../../../../../../../../../node_modules/@q4/nimbus-ui/dist/mixins";
@import "../../../../../../../../styles/mixins/general";
@import "../../../../../../../../styles/mixins/nimbus";

@include admin-app-namespace-wrap() {
  .scheduled-time-slot-view {
    &_header {
      display: flex;
    }
    &_timezone-header {
      display: flex;
      justify-content: flex-end;
    }

    &_slots-wrapper {
      height: 60vh;
      margin-bottom: gux(1.25);
    }

    &_slots-inner-wrapper {
      padding: gux(3);
      max-width: gux(88);
      width: 100%;
      margin: auto;
    }

    &_footer {
      display: flex;
      justify-content: flex-end;
      margin-top: gux(1.25);
    }
  }
}
