@import "../../../styles/mixins/general";
@import "../../../../node_modules/@q4/nimbus-ui/dist/variables";

@include app-namespace-wrap() {
  .attendee-form {
    max-width: $max-width--default;
    margin: auto;

    &_questions-header {
      margin-bottom: gux();
    }

    &_generate-password {
      .nui-info-icon {
        &--default {
          width: gux(1.5);
          height: gux(1.5);
          margin-left: gux(0.5);
          margin-bottom: gux(0.25);
        }
        &_button {
          width: gux(1.5);
          height: gux(1.5);
          line-height: gux(1.5);
          background: $ghost;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &_icon {
          font-size: gux();
          color: $slate;
        }

        .nui-tooltip {
          max-width: fit-content;

          &--extra-padding {
            width: fit-content;
            padding: gux(0.5) gux(1.25);
          }
        }
      }

      .nui-field {
        &_label-text {
          display: inline-block;
          overflow: visible;
        }

        &_children {
          flex-direction: column;

          .nui-button {
            width: gux(22);
            margin-bottom: gux();
          }
        }
      }
    }
  }
}
