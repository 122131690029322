@import "../../../node_modules/@q4/nimbus-ui/dist/variables";
@import "../../styles/mixins/general";

@include app-namespace-wrap() {
  $logo-size: 40px;

  .supported-browser {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    background-color: $white;
    padding: gux(8) gux(12);

    &_content {
      margin-top: gux(3);
    }

    &_logo {
      color: $q4-blue;
      font-size: $logo-size;
      text-align: right;
    }

    &_continue-footer {
      display: flex;
      justify-content: center;
      &-anchor {
        padding-right: gux(0.5);
      }
    }

    .placeholder-content {
      flex: 1 0 auto;

      &_image {
        max-width: 312px;
        height: 235px;
      }
    }

    .nui-placeholder-content_caption,
    .nui-placeholder-content_subtitle,
    .nui-placeholder-content_title {
      max-width: none;
    }

    .nui-button {
      padding: 0 gux(3);
    }

    &_title {
      font-size: $font-size--base;
      font-weight: $font-weight--bold;
      margin-bottom: gux(2);
    }

    &_container {
      display: flex;
      justify-content: space-around;
    }

    &_container-item {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &_logo {
      width: 80px;
      height: 80px;
    }

    &_anchor {
      margin-top: gux(2);
    }

    &_required {
      margin-top: gux(2);

      .supported-browser_title {
        margin-bottom: gux();
      }

      &-content {
        display: inline-block;
        text-align: left;
      }

      &-label {
        margin-right: gux();
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .supported-browser {
      padding: 0;

      &_continue-footer {
        margin-bottom: gux(2);
      }

      &_logo {
        width: 60px;
        height: 60px;
      }
    }
  }
}
