@import "../../../../../styles/mixins/general";
@import "../../../../../styles/variables";
@import "../../../../../styles/itinerary/variables";
@import "../../../../../components/companyBranding/companyBranding.component.scss";
@import "../../../../../styles/mixins/responsive";
@import "../../../../../styles/mixins/logo";

@include public-app-namespace-wrap() {
  .registration-header {
    &_logo {
      margin-bottom: gux(3);
      img {
        @include logo-style;
      }
    }

    &_title-container {
      margin-bottom: gux(7);
    }

    &_details h2 {
      font-family: $branding_font-family--header;
      font-weight: $font-weight--light;
      font-size: 2.77em;
      line-height: 1.2em;
    }

    &_title {
      font-size: $title-font-size;
      line-height: $title-line-height;
      font-weight: $font-weight--light;
      margin-bottom: gux(4);

      @include desktop() {
        width: 60%;
      }

      @include tablet-and-below() {
        width: 100%;
      }
    }

    &_date {
      font-weight: $font-weight--bold;
      font-size: $date-font-size;
    }

    &_description {
      font-weight: $font-weight--normal;
      font-size: $date-font-size;
      margin-top: gux(3);
      white-space: pre-line;

      > a {
        color: $branding_secondary;
        text-decoration: none;
        &:focus,
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
