@import "../../styles/mixins/general";
@import "../../styles/variables";

@include app-namespace-wrap() {
  .meeting-form-vendor {
    .dial-in-number {
      .nui-field_children {
        justify-content: flex-start;
        align-items: center;
      }

      &_field {
        width: 100%;
      }

      &_delete {
        margin-left: gux(3);
      }
    }
  }
}
