@import "../../../../../../../styles/mixins/general";
@import "../../../../../../../styles/variables";
@import "../../../../../../../styles/itinerary/variables";

@include app-namespace-wrap() {
  .powered-by {
    text-align: center;

    &_title,
    &_anchor {
      font-weight: $font-weight--light;
    }

    &_title {
      margin-top: 0;
      margin-bottom: 20px;
      font-size: $powered-by-title-font-size;
      line-height: $powered-by-title-line-height;
    }

    &_anchor {
      @extend %conference_font-color--base;
      font-size: $powered-by-anchor-font-size;
      opacity: 0.6;
      line-height: $powered-by-anchor-line-height;
      letter-spacing: 0.5px;

      i {
        opacity: 0.75;
        margin-left: 2px;
        font-size: $powered-by-anchor-icon-font-size;
        vertical-align: baseline;
      }
    }
  }
}
