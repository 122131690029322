@import "../../../../../node_modules//@q4/nimbus-ui/dist/variables";
@import "../../../../../node_modules/@q4/nimbus-ui/dist/mixins";

.import-table {
  height: 245px;
  display: flex;
  flex-direction: column;

  %importTableRow {
    display: flex;
    padding: gux() gux(3);
    border-bottom: 1px solid $soft-grey;

    &_text_el {
      flex-basis: 50%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:not(:last-child) {
        padding-right: gux(3);
      }
    }
  }

  color: $light-slate;
  background: $light-grey;
  border-radius: 3px;

  &_inner {
    padding: gux(0.5) 0 gux(2);
  }

  &_header {
    @extend %importTableRow;
    font-size: $font-size--subheader;
    padding: gux(1.5) gux(3);

    &-title,
    &-status {
      @extend %importTableRow_text_el;
    }
  }

  &_content {
    flex: 1 1;
    overflow: hidden;
  }

  &_row {
    @extend %importTableRow;
  }

  &_cell {
    flex-basis: 50%;
    width: 50%;
    padding-right: gux(2);

    &-title,
    &-status {
      @extend %importTableRow_text_el;
    }

    &-status,
    &-title-text {
      @include truncate;
    }

    &-title-text {
      max-width: 100%;
      display: block;
    }

    &-title-icon {
      flex: 1;
      margin-left: gux(0.5);
    }

    &-status {
      &--success {
        color: $teal;
      }

      &--fail {
        color: $spice;
      }
    }

    .link {
      display: flex;
      align-items: baseline;
    }
  }

  &_accordion-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
  }
}
