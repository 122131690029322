.personal-agenda-button {
  &--remove {
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }

    .nui-button {
      &_icon:before {
        content: "—";
      }
    }
  }
}
