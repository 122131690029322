@import "../variables";

/**
 Used to provide app with an extra layer of CSS Hierarchy as to overpower any third party plugins that provide styling
 */
@mixin app-namespace-wrap() {
  @at-root .#{$app-namespace} {
    @content;
  }
}

/**
 Used to provide app with an extra layer of CSS Hierarchy as to overpower any third party plugins that provide styling and
 prevent styles from the public and admin apps from conflicting
 */
@mixin admin-app-namespace-wrap() {
  @include app-namespace-wrap() {
    .#{$admin-app-namespace} {
      @content;
    }
  }
}
@mixin public-app-namespace-wrap() {
  @include app-namespace-wrap() {
    .#{$public-app-namespace} {
      @content;
    }
  }
}

@mixin ie11-flex-align-items-fix {
  &:after {
    content: "";
    display: block;
    min-height: inherit;
    font-size: 0;
  }
}
