@import "../../../../../styles/mixins/general";
@import "../../../../../../node_modules/@q4/nimbus-ui/dist/variables";

@include app-namespace-wrap() {
  .registration-confirmation {
    h2 {
      margin-bottom: gux(0.5);
      &:not(:first-child) {
        margin-top: gux(3);
      }
    }

    h3 {
      margin-top: gux(2);
    }
  }
}
