@mixin logo-style {
  max-height: gux(12);
  @include tablet() {
    max-width: gux(36);
  }

  @include phone() {
    max-width: gux(30);
  }
}
