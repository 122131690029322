// Global
//-------------------------------------//

$app-namespace: "events-conference";
$public-app-namespace: "events-conference_wrapper--public";
$admin-app-namespace: "events-conference_wrapper--admin";

$height--app-header: 64px;

// Colors
//-------------------------------------//
$midnight: #053a6b;
$deep-blue: #0a457c;
$ink: #10528f;
$q4-blue: #0f5ca3;
$steel: #1f6bb0;
$rain: #297ac5;
$sky: #3498db;
$surf: #4696e0;
$olympic: #428bcf;
$dark-teal: #0da084;
$teal: #1abc9c;
$avocado: #3a925f;
$lime: #2ecc71;
$apple: #aa2d40;
$ice: #94e9f6;
$sunshine: #f1c40f;
$citrus: #f1af0f;
$mustard: #dc9e27;
$tangerine: #fc7e26;
$ginger: #e67f22;
$amber: #d95608;
$spice: #ec6a4c;
$cherry: #e74c3c;
$eggplant: #6a3476;
$plum: #804a8c;
$raspberry: #a4305e;
$walnut: #935f39;
$black-smoke: #121517;
$gunpowder: #1d2124;
$dark-slate: #22272b;
$slate: #2a3035;
$titanium: #373b41;
$light-slate: #545b62;
$silver: #939ba0;
$ash: #cccccc;
$ghost: #dddddd;
$soft-grey: #e0e0e0;
$light-grey: #eeeeee;
$pale-grey: #fafafa;
$dove-grey: #767676;
$white: #ffffff;
$black: #000000;

// Media Query Breakpoints
//-------------------------------------//
$screen--mobile: 480px;
$screen--small: 768px;
$screen--medium: 1366px;
$screen--large: 1920px;

// Max-Width Breakpoints
//-------------------------------------//
$max-width--default: 980px;
$max-width--narrow: 800px;

// Fonts and Line Heights
//-------------------------------------//
$font-family--base: "Open Sans", sans-serif;

$font-weight--light: 300;
$font-weight--normal: 400;
$font-weight--bold: 600;

$font-size--display-large: 30px;
$font-size--title: 20px;
$font-size--subheader: 16px;
$font-size--icon: 16px;
$font-size--icon-small: 13px;
$font-size--base: 13px;
$font-size--caption: 11px;

$line-height--large: 40px;
$line-height--title: 24px;
$line-height--subheader: 24px;
$line-height--paragraph: 22px;
$line-height--base: 16px;
$line-height--caption: 16px;

// Common UI Variables
//-------------------------------------//
$grid-unit: 8px;

$component-size--default: $grid-unit * 5;
$component-size--small: $grid-unit * 4;

$circle-size--small: 12px; // used for field error
$circle-size--text: 18px; // used for button pulse
$circle-size--default: 24px; // used for ag-grid collapse / expand row

$toast-width: 420px;

$border-radius--xsmall: 2px;
$border-radius--small: 3px;
$border-radius--medium: 5px;
$border-radius--large: 8px;
$border-radius--xlarge: 32px;
$border-radius--circle: 50%;

$letter-spacing--small: 0.5px;
$letter-spacing--medium: 1px;

$opacity--full: 1;
$opacity--high: 0.75;
$opacity--medium: 0.5;
$opacity--low: 0.3;
$opacity--subtle: 0.15;
$opacity--transparent: 0;

// Z-Space Management
//-------------------------------------//
$z-index--base: 0;
$z-index--level-one: $z-index--base + 5;
$z-index--level-two: $z-index--base + 10;
$z-index--level-three: $z-index--base + 15; // used for global app header
$z-index--mask: 50;
$z-index--modal-level-one: $z-index--mask + 5;
$z-index--modal-level-two: $z-index--mask + 10;
$z-index--modal-level-three: $z-index--mask + 15; // used for global toast notification

// Animations
//-------------------------------------//
$transition-duration--fast: 0.15s;
$transition-duration--default: 0.3s;
$transition-duration--slow: 0.6s;
$transition-timing-function--default: ease-in-out;

// F u n c t i o n s
//-------------------------------------//

// Grid-Unit multiplier
@function gux($multiplier: 1) {
  @return $grid-unit * $multiplier;
}
