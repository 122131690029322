@import "../../../../../styles/mixins/general";
@import "../../../../../styles/variables";
@import "../../../../../styles/itinerary/variables";

@include public-app-namespace-wrap() {
  .speakers {
    &_content {
      overflow: hidden;
    }
  }
}
