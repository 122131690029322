@import "../../../../styles/mixins/general";
@import "../../../../styles/variables";

$width--block-section: 200px;

@include app-namespace-wrap() {
  .corporate-attendee-form {
    margin: 0 auto;
    max-width: $max-width--default;

    &_block {
      display: flex;
    }

    &_block-section {
      width: $width--block-section;
    }

    &_question-header {
      margin-top: gux(2);
      margin-bottom: gux();
    }
  }
}
