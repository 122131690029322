@import "../../styles/mixins/general";
@import "../../styles/variables";

$width--block-section: 200px;

@include app-namespace-wrap() {
  .registrant-form {
    margin: 0 auto;
    max-width: $max-width--default;

    &_questions-header {
      margin-bottom: gux();
    }

    &_block {
      display: flex;
    }

    &_block-section {
      width: $width--block-section;
    }
  }
}
