@import "../../styles/mixins/general";

@include app-namespace-wrap() {
  .questions-form {
    a {
      color: $light-slate;
      text-decoration: none;
      pointer-events: none;
    }

    &_label {
      .nui-field_label-text--truncate {
        white-space: normal;
      }
    }
  }
}
