$icomoon-font-family: "q4icons" !default;
$icomoon-font-path: "fonts" !default;

$q4i-search-2pt: "\e915";
$q4i-chat-fill: "\e90f";
$q4i-cog-4pt: "\e910";
$q4i-import-4pt: "\e911";
$q4i-paperclip-4pt: "\e914";
$q4i-send-4pt: "\e90b";
$q4i-menu: "\e90a";
$q4i-linkedin: "\e900";
$q4i-email: "\e901";
$q4i-facebook: "\e902";
$q4i-instagram: "\e905";
$q4i-twitter: "\e906";
$q4i-youtube: "\e909";
$q4i-question-mark-2pt: "\eb42";
$q4i-surveillance-4pt: "\e916";
$q4i-hidden-4pt: "\e917";
$q4i-link-chain-4pt: "\e918";
$q4i-time-2pt: "\ebfa";
$q4i-time-4pt: "\ebfb";
$q4i-checkmark-2pt: "\e9d4";
$q4i-checkmark-4pt: "\e9dd";
$q4i-clone-duplicate-2pt: "\e9e2";
$q4i-clone-duplicate-4pt: "\e9e3";
$q4i-note-2pt: "\eb10";
$q4i-note-4pt: "\eb11";
$q4i-utility-4pt: "\ebbd";
$q4i-close-2pt: "\e995";
$q4i-close-4pt: "\e996";
$q4i-arrow-left-2pt: "\e98e";
$q4i-arrow-left-4pt: "\e93c";
$q4i-chat-2pt: "\e9cc";
$q4i-chat-4pt: "\e9cd";
$q4i-admin-2pt: "\e958";
$q4i-admin-4pt: "\e95b";
$q4i-activity-2pt: "\e942";
$q4i-activity-4pt: "\e943";
$q4i-conference-2pt: "\ea14";
$q4i-conference-4pt: "\ea15";
$q4i-team-2pt: "\ebee";
$q4i-team-4pt: "\ebef";
$q4i-data-2pt: "\ea0b";
$q4i-data-4pt: "\ea0c";
$q4i-sync-or-fetch-2pt: "\e91f";
$q4i-sync-or-fetch-4pt: "\eb62";
$q4i-q4-team-4pt: "\e90d";
$q4i-q4-team-2pt: "\e90e";
$q4i-home-2pt: "\ea5a";
$q4i-home-4pt: "\ea5b";
$q4i-undo-2pt: "\eb8d";
$q4i-undo-4pt: "\eb8e";
$q4i-link-2pt: "\ea7c";
$q4i-link-4pt: "\ea7d";
$q4i-image-2pt: "\e907";
$q4i-image-4pt: "\e908";
$q4i-export-2pt: "\ea3c";
$q4i-export-4pt: "\ea3d";
$q4i-calendar-2pt: "\e903";
$q4i-calendar-4pt: "\e904";
$q4i-edit-2pt: "\ea27";
$q4i-edit-4pt: "\ea28";
$q4i-contact-2pt: "\e9f7";
$q4i-contact-4pt: "\e9f8";
$q4i-logo: "\e9a9";
$q4i-trashbin-2pt: "\e9b8";
$q4i-trashbin-4pt: "\e930";
$q4i-caret-left-2pt: "\e912";
$q4i-caret-left-4pt: "\e913";
$q4i-caret-down-2pt: "\e955";
$q4i-caret-down-4pt: "\e967";
$q4i-add-2pt: "\e949";
$q4i-add-4pt: "\e94a";

