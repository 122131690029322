@import "../../../styles/mixins/general";
@import "../../../styles/mixins/responsive";
@import "../../../styles/variables";
@import "../../../styles/itinerary/variables";

@include app-namespace-wrap() {
  .attendee_profile {
    &_content_wrapper {
      margin: auto;
      padding: gux(5) 0;
      width: 100%;
      max-width: 1060px;
    }

    .nui-checkbox {
      .nui-toggle-input-base {
        &_control {
          background-color: $branding_tertiary-contrast;
        }

        &_input:focus + .nui-toggle-input-base_label .nui-toggle-input-base_control {
          background-color: $branding_tertiary-contrast;
        }
      }

      &.nui-toggle-input-base--checked {
        .nui-toggle-input-base {
          &_control {
            background-color: $branding_primary;

            .nui-checkbox_icon {
              color: $branding_primary-contrast;
            }
          }

          &_input:focus + .nui-toggle-input-base_label .nui-toggle-input-base_control {
            background-color: $branding_primary;
          }
        }
      }
    }
  }
}
