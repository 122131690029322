@import "../../../../../../../node_modules/@q4/nimbus-ui/dist/variables";
@import "../../../../../../../node_modules/@q4/nimbus-ui/dist/mixins";
@import "../../../../../../styles/mixins/general";
@import "../../../../../../styles/mixins/nimbus";

@include admin-app-namespace-wrap() {
  .meeting-scheduler {
    &_content {
      padding: gux(4);
    }

    &_description {
      margin-top: gux();
      margin-bottom: gux(2);
      display: flex;
    }

    &_time-zone {
      display: flex;
      justify-content: flex-end;
    }

    &_date-tabs {
      border-bottom: $soft-grey solid 1px;
      .nui-tabs {
        background-color: transparent;
        &_item {
          letter-spacing: 0.5px;

          &--selected {
            color: $rain;
            border-bottom-color: $rain;
          }
        }
      }
    }

    &_calendar-slot {
      min-height: gux(6);
      transition: background-color $transition-duration--default $transition-timing-function--default;

      &--available {
        position: relative;
        &:after {
          content: "";
          width: 100%;
          height: 1px;
          background-color: $light-grey;
          position: absolute;
          top: 50%;
        }
        &:hover {
          border-radius: $border-radius--small;
          cursor: pointer;
        }
      }

      &--no-resource {
        display: flex;
        align-items: center;
        min-width: gux(8);

        .rbc-label {
          padding-left: gux(2);
        }
      }

      &--unavailable {
        background: repeating-linear-gradient(-52deg, $white, $white 6px, $light-grey 2px, $light-grey 10px);
      }
    }

    &_calendar-slot-corporate {
      .meeting-scheduler_calendar-slot--available {
        &:hover {
          border: 1px solid $teal;
          background-color: rgba($teal, $opacity--subtle);
        }
      }
    }

    &_calendar-slot-investor {
      .meeting-scheduler_calendar-slot--available {
        &:hover {
          border: 1px solid $q4-blue;
          background-color: rgba($q4-blue, $opacity--subtle);
        }
      }
    }

    &_calendar-slot-conflict {
      .meeting-scheduler_calendar-slot--available {
        &:hover {
          cursor: initial;
        }
      }
    }

    &_calendar-event {
      border-radius: $border-radius--small;
      border: none;
      font-size: $font-size--base;
      min-height: gux(6);
      justify-content: center;
      align-items: center;

      > div {
        font-size: 100%;
        width: auto;
        height: auto;
        flex-grow: 0;
      }

      &--corporate {
        background-color: $dark-teal;
      }

      &--corporate-presentation {
        background-color: $light-slate;
      }

      &--investor {
        background-color: $q4-blue;
      }
    }

    .rbc {
      &-today {
        background-color: transparent;
      }

      &-time-view-resources {
        border: none;
        border-top: 1px solid $light-grey;
      }

      &-time-gutter {
        border-right-color: $light-grey;
        color: $silver;
        margin-right: 0;
      }

      &-time-content > * + * > * {
        border-left: none;
      }

      &-time-header {
        .rbc-row-resource {
          border: none;
        }

        .rbc-time-header-content:nth-child(2) {
          background-color: $light-grey;
        }
      }

      &-time-header-content,
      &-time-header-gutter {
        border: none;
      }

      &-header {
        text-transform: uppercase;
        line-height: gux(2);
        font-size: $font-size--base;
        font-weight: $font-weight--normal;
        letter-spacing: 0.5px;
        padding: gux() 0;
        border-bottom: none;
      }

      &-allday-cell {
        display: none;
      }

      &-time-content {
        border-top: 2px solid $silver;
      }

      &-timeslot-group {
        border-bottom-color: $light-grey;

        &:after {
          content: "";
          height: gux();
          background-color: $light-grey;
        }
      }

      &-time-gutter {
        .rbc-timeslot-group {
          border-bottom-color: transparent;
          &:after {
            background-color: transparent;
          }
        }
      }

      &-current-time-indicator {
        display: none;
      }

      &-events-container {
        margin-right: auto;

        .meeting-scheduler_calendar-event--corporate-presentation .rbc-event-content {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
          text-align: center;
        }
      }

      &-event {
        z-index: 1;

        &-label {
          font-weight: $font-weight--bold;
        }
      }
    }
  }
}
