@import "../../../../../styles/mixins/general";
@import "../../../../../styles/mixins/paginationBlock";
@import "../../../../../../node_modules/@q4/nimbus-ui/dist/variables";
@import "../../../../../components/companyBranding/companyBranding.component.scss";
@import "../../../../../styles/itinerary/variables";

@include app-namespace-wrap() {
  .meeting-request {
    margin-bottom: gux(4);

    .nui-select {
      .nui-select__control {
        min-width: 100%;
      }
    }

    &_corporate-profile {
      margin-top: gux(2);
      margin-bottom: gux(3);
    }

    &_corporate-empty-state {
      margin-bottom: gux(3);
    }

    &_corporate-profile-header {
      border-bottom: 1px solid #979797;

      .nui-grid_col {
        margin-bottom: gux();
      }
    }

    &_corporate-profile-row {
      border-bottom: 1px solid #cccccc;
      padding: gux(2) 0;
    }

    &_corporate-profile-info {
      display: flex;
    }

    &_corporate-name,
    &_corporate-ticker {
      margin: 0;
    }

    &_corporate-name {
      font-size: $subheading-font-size;
      line-height: $subheading-line-height;
      font-weight: $font-weight--normal;
      word-break: break-word;
    }

    &_corporate-ticker {
      font-weight: $font-weight--light;
      line-height: $line-height--paragraph;
    }

    &_corporate-profile-logo {
      width: 60px;
      height: 60px;
      background-size: contain;
      background-repeat: no-repeat;
      margin-right: gux(2);
    }

    &_corporate-attendees {
      word-break: break-word;
    }

    &_corporate-meeting-interest {
      .nui-grid_col {
        .nui-text {
          font-family: inherit;
        }
      }
    }

    .nui-text {
      font-weight: $font-weight--normal;
      color: $light-slate;
    }

    .nui-search {
      background-color: $white;

      *::placeholder {
        color: $silver;
      }

      *,
      &_icon {
        color: $light-slate;
      }
    }

    &_corporate-pagination {
      @include pagination-block;
    }
  }
}
